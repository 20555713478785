<template>
  <v-card
    id="perseu-card"
    :class="`elevation-${elevation} padding-bigger pb-3 ${cardClass}
      ${fillHeight && 'fill-height'}`"
  >
    <!-- <v-toolbar :color="color">
      <v-toolbar-title :class="classTitle">
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        
      </v-toolbar-items> 
    </v-toolbar>-->
    <v-card-title class="mb-3 black--text">
      <div
        :class="`d-flex align-center flex-fill ${
          titleCenter && 'justify-center'
        }`"
      >
        <slot name="title-left"></slot>
        <h2 :class="`title font-weight-bold ${classTitle}`">
          {{ title }}
        </h2>
      </div>
      <v-spacer v-if="$slots.titleRight"></v-spacer>
      <slot name="title-right"></slot>
    </v-card-title>
    <slot></slot>
    <v-card-text v-if="$slots.content">
      <slot name="content"></slot>
    </v-card-text>
    <v-flex v-if="$slots.actions" class="text-right">
      <v-card-actions>
        <v-flex>
          <slot name="actions"></slot>
        </v-flex>
      </v-card-actions>
    </v-flex>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    color: { type: String, default: "secondary" },
    classTitle: { type: String, default: "secondary--text" },
    cardClass: {
      type: String,
      default: "",
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    titleCenter: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: [Number, String],
      default: 3,
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
