/* eslint-disable no-console */

import { register } from "register-service-worker";
import { findNotSynced } from "@/services/local/therapies-service";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.VUE_BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      //registration.update();
      /* let worker = registration.waiting;
      if (worker) worker.postMessage("skipWaiting") */
      console.log("registered");
    },
    cached() {
      console.log("cached");
    },
    updatefound() {
      //registration.update();
      console.log("updatedfound");
    },
    updated() {
      /* registration.update();
      let worker = registration.waiting;
      if (worker) worker.postMessage("skipWaiting"); */
      console.log("updated");
      alert("Atualização encontrada: recarregando...");

      window.location.reload(true);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
