const state = {
  selected: {},
};

const mutations = {
  setSelected(state, value) {
    state.selected = value;
  },
};

const actions = {
  selectOne({ commit }, chromosome) {
    commit("setSelected", chromosome);
    commit("dialogs/setChromosomes", true, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
