<template>
  <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        dense
        :label="label"
        prepend-icon="access_time"
        readonly
        v-bind="attrs"
        outlined
        hide-details
        :rules="rules"
        :disabled="disabled"
        v-on="disabled ? null : on"
      />
      <div v-show="!!errorMessage" class="text-center error--text">
        {{ errorMessage }}
      </div>
    </template>
    <v-time-picker v-if="dialog && !disabled" v-model="time" full-width>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dialog = false">Cancelar</v-btn>
      <v-btn text color="primary" @click="emitInput">Selecionar</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      dialog: false,
      time: this.value,
    };
  },
  watch: {
    value(value) {
      this.time = value;
    },
  },
  methods: {
    emitInput() {
      this.$emit("input", this.time);
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
