const state = {
  selectedChakra: {},
};

const mutations = {
  setSelected(state, value) {
    state.selectedChakra = value;
  },
};

const actions = {
  selectOne({ commit }, chakra) {
    commit("setSelected", chakra);
    commit("dialogs/setChakras", true, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
