import { Validator } from "vee-validate";

const dictionary = {
  custom: {
    password: {
      required: "O campo senha é obrigatório.",
    },
    confirmed_password: {
      confirmed: "As senhas precisam ser iguais!",
      required: "O campo confirme a senha é obrigatório.",
    },
  },
};

Validator.localize("pt_BR", dictionary);
