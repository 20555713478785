<template>
  <div>
    <p class="font-weight-bold mb-0">{{ label }}</p>
    <v-list v-if="hasItems">
      <v-list-item-group
        :value="value"
        multiple
        color="primary"
        @change="emitInput"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index.name"
          :value="item"
          class="py-2 list-item"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon>
              <v-icon>
                {{ active ? "check_circle" : "panorama_fish_eye" }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="black--text">
              {{ item.name }}
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else>
      <div class="grey--text">Nenhum(a).</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasItems() {
      return this.items && this.items.length > 0;
    },
  },
  methods: {
    emitInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
