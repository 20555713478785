import db from "@/database";
import * as Constants from "@/utils/constants";

export const findOne = (id) => db.point.get(id);

export const findOneTrackingPoint = (id) =>
  db.point.get({ id, type: Constants.TRACKING_POINT_TYPE });

export const findOneImpactionPoint = (id) =>
  db.point.get({ id, type: Constants.IMPACTION_POINT_TYPE });

export const findByIds = (ids) => db.point.where("id").anyOf(ids).toArray();
