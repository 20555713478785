<template>
  <v-list three-line class="elevation-3 rounded-10 mb-0">
    <div class="d-flex align-center">
      <div v-if="!$slots.title" class="py-3 pl-5 pr-1 font-weight-bold">
        {{ title }}
      </div>
      <div v-else>
        <slot name="title" />
      </div>
      <div v-if="explanation">
        <explanation-icon :explanation="explanation" :name="title" />
      </div>
    </div>
    <v-divider />
    <div class="px-2">
      <slot />
    </div>
  </v-list>
</template>

<script>
export default {
  name: "ReviewCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
