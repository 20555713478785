import api from "./api-service";
import axios from "axios";
import querystring from "querystring";

export const getProfilePreSignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/profile?" + querystring.stringify({ contentType })
  );
};

export const getCertificatePreSignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/certificate?" + querystring.stringify({ contentType })
  );
};

export const getSignedConsentTermPresignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/signed-consent-term?" +
      querystring.stringify({ contentType })
  );
};

export const awsPutImage = ({ url, key, contentType, fileImage }) =>
  axios.put(url, fileImage, {
    headers: {
      Key: key,
      "Content-Type": contentType,
    },
  });

export const downloadConsentFormFromAws = async (url) => {
  const config = { responseType: "blob" };
  const response = await axios.get(url, config);
  return new File([response.data], "Termo de Consentimento - AppBio");
};
