import { _MUTATIONS } from "./types";

export const mutations = {
  [_MUTATIONS.SET_SEQUENCE](state, value) {
    state.sequence = value;
  },
  [_MUTATIONS.SET_PROPERTIES](state, value) {
    state.properties = value;
  },
  [_MUTATIONS.CLEAR_SEQUENCE](state) {
    state.sequence = [];
    state.properties = [];
    state.selectedItems = [];
  },
  [_MUTATIONS.SET_SELECTED_ITEMS](state, value) {
    state.selectedItems = value;
  },
  [_MUTATIONS.ADD_SELECTED_ITEM](state, value) {
    state.selectedItems.push(value);
  },
  [_MUTATIONS.REMOVE_SELECTED_ITEM](state, item) {
    const indexSelectedItem = state.selectedItems.findIndex(
      (selectedItem) =>
        selectedItem.id === item.id &&
        selectedItem.property.slug === item.property.slug
    );
    state.selectedItems.splice(indexSelectedItem, 1);
  },
  [_MUTATIONS.CHANGE_TUMORAL_PHENOMENA_DIALOG](state, value) {
    state.dialogs.tumoralPhenomena = value;
  },
  [_MUTATIONS.CHANGE_IMPACTION_POINTS_DIALOG](state, value) {
    state.dialogs.impactionPoints = value;
  },
  [_MUTATIONS.SET_INDEX_SELECTED_PROPERTY](state, value) {
    state.indexSelectedProperty = value;
  },
};
