const state = {
  types: [],
  selectedType: {},
  selectedOligoelement: {},
};

const mutations = {
  selectType(state, value) {
    state.selectedType = value;
  },
  selectOligoelement(state, value) {
    state.selectedOligoelement = value;
  },
  addSourceToSelectedOligoelement(state, { name }) {
    state.selectedOligoelement.sources.push({ name });
  },
};

const actions = {
  selectType({ commit }, type) {
    commit("selectType", type);
    commit("dialogs/setOligoelementsTypes", true, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
