const state = {
  active: false,
  dialog: false,
};

const mutations = {
  changeActive(state, value) {
    state.active = value;
  },
  changeDialog(state, value) {
    state.dialog = value;
  },
};

const actions = {
  async openDialog({ commit }) {
    commit("changeDialog", true);
  },

  async closeDialog({ commit }) {
    commit("changeDialog", false);
  },
};

const getters = {
  isActive(state) {
    return state.active;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  plugins: [],
};
