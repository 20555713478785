import Vue from "vue";

import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1AE06E",
        secondary: "#1356A1",
        terciary: "#FFF99F",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#09b345",
        warning: "#FFC107",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "md",
  },
});
