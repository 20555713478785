import Dexie from "dexie";

const db = new Dexie("ParMagnetico");

db.version(1).stores({
  config: "++id, lastUpdate",
  category: "id, name, langId, isSecondLevel, pares",
  type: "id, name, slug, explanation, order, langId",
  symptom: "id, name, langId, pares",
  pathogen: "id, name, langId, pares",
  sequence: "id, name, pointOrder, typeId, isDefault",
  par: "id, name, description, recommendations, symptoms, pathogens, typeId, categories, checkPointsOrder",
  point: "id, type, name, imageDescription",
  pointsOrder: "id, order, trackingPointId, impactionPointId, parId",
  therapy:
    "++localId, id, patient, question, diagnose, synced, chosenSequence, step, inProgress, createdAtLocal, finishedAt, status",
  maritalStatus: "id, name, langId",
  tumoralPhenomenon: "id, name, categories, instructions, langId",
  resolutionZone: "id, name, trackingPointId, impactionPointId, langId",
  vascularPhenomenon: "id, name",
  potentialSpace: "id, name, instructions, categories, langId",
  flowType: "id, name, pointsOrder",
  oligoelementsType: "id, oligoelements",
  chakra: "id, order, name, location, procedures, explanation, homeUse",
  chromosome: "id, order, description",
  therapyTemplates: "id, name, order, createdBy, createdAt, description, items",
  settings: "id, consentTermUrl",
  complementaryTherapy: "id, name, description, order",
  images: "url, blob",
});

export default db;
