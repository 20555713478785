import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import colors from "@/misc/colors";

const globalOptions = {
  confirmButtonColor: colors.secondary,
};

Vue.use(VueSweetalert2, globalOptions);

Vue.prototype.$successAlert = ({ title = "Sucesso", message }) => {
  Vue.swal({
    title,
    text: message,
    icon: "success",
    iconColor: colors.primary,
  });
};

Vue.prototype.$errorAlert = ({ title = "Erro", message }) => {
  Vue.swal(title, message, "error");
};

Vue.prototype.$infoAlert = ({ title = "Informação", message }) => {
  Vue.swal(title, message, "info");
};

Vue.prototype.$warningAlert = ({ title = "Alerta", message }) => {
  Vue.swal(title, message, "warning");
};
