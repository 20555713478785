import {
  getAll,
  getPropertyObjectByTemplateItem,
} from "@/services/local/therapy-templates-services";
import { findOne as findOneType } from "@/services/local/types-service";
import { findOne as findOneCategory } from "@/services/local/categories-service";
import { findOne as findOneSymptom } from "@/services/local/symptoms-service";
import { findOne as findOnePathogen } from "@/services/local/pathogens-service";
import { TherapyTemplateItemEnum } from "@/domains/therapy/steps/mount-sequence/domain/therapy-template-item-enum";
import { SEQUENCE } from "@/domains/therapy/steps/mount-sequence/store/sequence/types";

const state = {
  dialog: false,
  allTherapyTemplates: [],
};

const mutations = {
  setDialog(state, value) {
    state.dialog = value;
  },
  setAllTherapyTemplates(state, value) {
    state.allTherapyTemplates = value;
  },
};

const actions = {
  async populateAllTherapyTemplates({ commit }) {
    const response = await getAll();
    const templatesToList = response.map((therapyTemplate) => ({
      id: therapyTemplate.id,
      name: therapyTemplate.name,
      createdBy: therapyTemplate.createdBy,
      items: therapyTemplate.items,
    }));
    commit("setAllTherapyTemplates", templatesToList);
  },
  async selectTemplate({ commit, state, dispatch }, idSelectedTemplate) {
    dispatch(SEQUENCE.ACTIONS.CLEAR_SELECTED_ITEMS, null, { root: true });
    const selectedTemplate = state.allTherapyTemplates.find(
      (tt) => tt.id === idSelectedTemplate
    );

    const sequenceItems = await Promise.all(
      selectedTemplate.items.map(async (item) => {
        const typesMapToPopulate = new Map([
          [TherapyTemplateItemEnum.TYPE, findOneType],
          [TherapyTemplateItemEnum.SYMPTOM, findOneSymptom],
          [TherapyTemplateItemEnum.PATHOGEN, findOnePathogen],
          [TherapyTemplateItemEnum.CATEGORY, findOneCategory],
        ]);

        const functionToFind = typesMapToPopulate.get(item.property);

        const result = await functionToFind(item.valueId);

        const property = getPropertyObjectByTemplateItem(item.property);

        return {
          id: result.id,
          name: result.name,
          slug: result.slug,
          selected: true,
          property,
          typeSequenceId: item.typeSequenceId,
        };
      })
    );

    commit("setDialog", false);
    commit(SEQUENCE.MUTATIONS.SET_SELECTED_ITEMS, sequenceItems, {
      root: true,
    });
  },
};

const getters = {};

export default { namespaced: true, state, mutations, actions, getters };
