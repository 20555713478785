const state = {
  menuSyncDone: false,
  syncButtonDone: false,
  planSubscriptionDone: false,
  syncronizationDone: false,
};

const mutations = {
  changeMenuSyncDone(state, value) {
    state.menuSyncDone = value;
  },
  changeSyncButtonDone(state, value) {
    state.syncButtonDone = value;
  },
  changePlanSubscriptionDone(state, value) {
    state.planSubscriptionDone = value;
  },
  changeSincrionizationDone(state, value) {
    state.syncronizationDone = value;
  },
};

const actions = {
  async changeMenuSyncDone({ commit }, value) {
    commit("changeMenuSyncDone", value);
  },
  async changeSyncButtonDone({ commit }, value) {
    commit("changeSyncButtonDone", value);
  },
  async changePlanSubscriptionDone({ commit }, value) {
    commit("changePlanSubscriptionDone", value);
  },
  async changeSincrionizationDone({ commit }, value) {
    commit("changeSincrionizationDone", value);
  },
};

const getters = {
  getMenuSyncDone(state) {
    return state.menuSyncDone;
  },
  getSyncButtonDone(state) {
    return state.syncButtonDone;
  },
  getPlanSubscriptionDone(state) {
    return state.planSubscriptionDone;
  },
  getSyncronizationDone(state) {
    return state.syncronizationDone;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
