<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          :large="large"
          class="font-weight-black"
          :color="color"
          dark
          v-on="on"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
