<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        :large="large"
        :disabled="disabled"
        icon
        v-on="on"
        @click.stop="$emit('click')"
      >
        <v-icon :large="large" :color="color">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "secondary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
