export default [
  {
    path: "/myAccount",
    name: "myAccount",
    icon: "person",
    visible: true,
    component: () =>
      import("@/domains/user/shared/presentation/pages/MyAccount"),
    meta: {
      requiresAuth: true,
      text: "userMenu.myAccount",
      id: "my_account",
    },
  },
  {
    path: "/profile",
    name: "profile",
    icon: "person_search",
    visible: true,
    component: () =>
      import("@/domains/user/therapist/presentation/pages/Profile"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "userMenu.profile",
      id: "profile",
    },
  },

  {
    path: "/subscriptions/confirmation",
    name: "subscriptions.confirmation",
    icon: "shop",
    visible: false,
    component: () =>
      import("@/domains/subscriptions/presentation/pages/Confirmation"),
    meta: {
      requiresAuth: true,
      id: "confirmation",
    },
  },
  {
    path: "/subscriptions/change-plan-confirmation",
    name: "subscriptions.changePlanConfirmation",
    icon: "shop",
    visible: false,
    component: () =>
      import(
        "@/domains/subscriptions/presentation/pages/ChangePlanConfirmation"
      ),
    meta: {
      requiresAuth: true,
      id: "change_plan_confirmation",
    },
  },
  {
    path: "/my-subscription",
    name: "subscriptions.my",
    icon: "shop",
    visible: true,
    component: () =>
      import("@/domains/subscriptions/presentation/pages/MySubscription"),
    meta: {
      requiresAuth: true,
      text: "userMenu.mySubscription",
      id: "my_subscription",
      role: "therapist",
    },
  },
  {
    path: "/subscriptions/cancellation",
    name: "subscriptions.cancellation",
    icon: "shop",
    visible: false,
    component: () =>
      import("@/domains/subscriptions/presentation/pages/Cancellation"),
    meta: {
      requiresAuth: true,
      id: "cancellation",
    },
  },
];
