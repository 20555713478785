<template>
  <v-row>
    <v-col cols="12">
      <back-button color="grey" @click="$emit('back')" />
      {{ title }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CardTitleWithBackButton",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
