<template>
  <div>
    <v-app id="app">
      <v-main class="grey lighten-3 app-main">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-main>
      <loading />
      <sync-dialog />
      <install-sheet-ios />
      <confirm-dialog ref="confirm" />
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    loading: () => import("@/layouts/components/Loading"),
    "sync-dialog": () =>
      import("@/domains/sync/presentation/components/SyncDialog"),
    "install-sheet-ios": () => import("@/components/shared/InstallSheetIOS"),
  },
  data: () => ({
    appName: "Par Magnético - Administrador",
    selectedLang: "pt_BR",
  }),
  computed: {
    isLogged() {
      return this.$store.getters["login/isLogged"];
    },
  },
  created() {
    this.$store.dispatch("language/setBrowserLanguage");
  },
  methods: {
    redirect() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style>
.image-link {
  cursor: pointer;
}

.flag-icon {
  margin-left: 5px;
}
</style>
