import * as typesService from "@/services/local/types-service";
import * as symptomsService from "@/services/local/symptoms-service";
import * as pathogensService from "@/services/local/pathogens-service";
import * as categoriesService from "@/services/local/categories-service";
import Vue from "vue";
import { SlugEnum } from "@/domains/therapy/steps/mount-sequence/domain/slug-enum";
import * as tumoralPhenomenaService from "@/services/local/tumoral-phenomena-service";
import * as vascularPhenomenaService from "@/services/local/vascular-phenomena-service";
import * as potentialSpacesService from "@/services/local/potential-spaces-service";
import * as flowTypesService from "@/services/local/flow-types-service";
import * as oligoelementsService from "@/services/local/oligoelements-service";
import * as chakrasService from "@/services/local/chakras-service";
import * as chromosomesService from "@/services/local/chromosomes-service";
import * as paresService from "@/services/local/pares-biomagneticos-service";
import * as sequencesService from "@/services/local/sequences-service";
import { findByIds } from "@/services/local/points-service";
import * as pointsOrderService from "@/services/local/points-order-service";
import * as pointsService from "@/services/local/points-service";
import * as complementaryTherapyService from "@/services/local/complementary-therapy-service";
import _ from "lodash";
import { _ACTIONS, _MUTATIONS } from "./types";

export const actions = {
  [_ACTIONS.TOGGLE_ITEM_FROM_PROPERTY_TO_SEQUENCE](
    { commit, state },
    { item, selectedProperty }
  ) {
    const isItemSelected = state.selectedItems.some(
      (selectedItem) =>
        selectedItem.id === item.id &&
        selectedItem.property.slug === selectedProperty.slug
    );

    if (!isItemSelected) {
      commit(_MUTATIONS.ADD_SELECTED_ITEM, {
        id: item.id,
        name: item.name,
        selected: item.selected,
        slug: item.slug,
        property: {
          name: selectedProperty.name,
          slug: selectedProperty.slug,
        },
      });
    } else {
      commit(_MUTATIONS.REMOVE_SELECTED_ITEM, item);
    }
  },
  [_ACTIONS.CLEAR_SELECTED_ITEMS]({ commit }) {
    commit(_MUTATIONS.SET_SELECTED_ITEMS, []);
  },
  async [_ACTIONS.MOUNT_SEQUENCE]({ state, dispatch, commit }) {
    try {
      commit("therapy/saveSequence", state.selectedItems, { root: true });

      dispatch("loading/openDialog", null, { root: true });
      const stepsFunctions = new Map([
        ["type", (item) => dispatch(_ACTIONS.STEPS_TYPE, item)],
        ["symptom", (item) => dispatch(_ACTIONS.STEPS_OTHER_PROPERTIES, item)],
        ["pathogen", (item) => dispatch(_ACTIONS.STEPS_OTHER_PROPERTIES, item)],
        ["category", (item) => dispatch(_ACTIONS.STEPS_OTHER_PROPERTIES, item)],
      ]);

      const sequence = state.selectedItems.map(async (item) => {
        const functionToExecute = stepsFunctions.get(item.property.slug);

        const steps = await functionToExecute(item);

        return {
          name: item.name,
          explanation: item.explanation,
          propertyLabel: item.property.name,
          steps,
        };
      });
      const mountedSequence = await Promise.all(sequence);
      commit(_MUTATIONS.SET_SEQUENCE, mountedSequence);
    } catch (error) {
      Vue.prototype.$errorHandler(error);
    } finally {
      dispatch("loading/closeDialog", null, { root: true });
    }
  },

  /**
   * Pares por tipo
   * @param state
   * @param item
   * @returns {Promise<*>}
   */
  async [_ACTIONS.STEPS_TYPE]({ state }, item) {
    const mapFinds = new Map([
      [SlugEnum.FENOMENOS_TUMORAIS, tumoralPhenomenaService.findAll],
      [SlugEnum.FENOMENOS_VASCULARES, vascularPhenomenaService.findAll],
      [SlugEnum.ESPACOS_POTENCIAIS, potentialSpacesService.findAll],
      [SlugEnum.TIPOS_DE_FLUXO, flowTypesService.findAll],
      [SlugEnum.OLIGOELEMENTOS, oligoelementsService.findAll],
      [SlugEnum.CHAKRAS, chakrasService.findAll],
      [SlugEnum.TERAPIAS_COMPLEMENTARES, complementaryTherapyService.findAll],
      [SlugEnum.CROMOSSOMOS, chromosomesService.findAll],
      [
        SlugEnum.PARES_PSICOEMOCIONAIS,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.PARES_PSICOEMOCIONAIS
          );
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.INTOXICACAO,
        async () => {
          const { id } = await typesService.getBySlug(SlugEnum.INTOXICACAO);
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.HORMONAIS,
        async () => {
          const { id } = await typesService.getBySlug(SlugEnum.HORMONAIS);
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.ESPIRITUAIS_E_MALIGNOS,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.ESPIRITUAIS_E_MALIGNOS
          );
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.SOFROLOGICO,
        async () => {
          const { id } = await typesService.getBySlug(SlugEnum.SOFROLOGICO);
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.HERPES_CHECAGEM,
        async () => {
          const { id } = await typesService.getBySlug(SlugEnum.HERPES_CHECAGEM);
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.MOVIMENTO_3D1_3D2,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.MOVIMENTO_3D1_3D2
          );
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.PROTOCOLO_BASICO,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.PROTOCOLO_BASICO
          );
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.PROTOCOLO_LINFATICO,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.PROTOCOLO_LINFATICO
          );
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.PROTOCOLO_FSMI,
        async () => {
          const { id } = await typesService.getBySlug(SlugEnum.PROTOCOLO_FSMI);
          return paresService.findParesByTypeId(id);
        },
      ],
      [
        SlugEnum.PARES_ESPECIAIS_NIVEL_1_2,
        async () => {
          const { id } = await typesService.getBySlug(
            SlugEnum.PARES_ESPECIAIS_NIVEL_1_2
          );
          return paresService.findParesByTypeId(id);
        },
      ],
    ]);

    const findAllFunction = mapFinds.get(item.slug);
    if (findAllFunction) {
      const results = await findAllFunction();
      results.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });

      return results.map((result) => {
        const customTitleMap = new Map([
          ["cromossomos", `Cromossomo(a) ${result.order}`],
        ]);
        return {
          title: customTitleMap.get(item.slug) || result.name,
          type: result,
          slug: item.slug,
        };
      });
    } else {
      /**
       * Reservatório e rastreio
       */

      const typeId = item.id;
      let sequenceType;
      if (item.typeSequenceId) {
        sequenceType = await sequencesService.findOne(item.typeSequenceId);
      } else {
        sequenceType = await sequencesService.findDefaultByTypeId(typeId);
      }
      if (!sequenceType) return;

      const ids = sequenceType.pointOrder
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
        .map((pointOrder) => pointOrder.trackingPointId);

      const orderAndIds = sequenceType.pointOrder.map((pointOrder) => {
        return {
          id: pointOrder.trackingPointId,
          order: pointOrder.order,
        };
      });

      const trackingPoints = await findByIds(ids);

      return trackingPoints
        .map((point) => {
          return {
            title: point.name,
            order: orderAndIds.find((orderAndId) => orderAndId.id === point.id)
              ?.order,
            slug: item.slug,
            trackingPoint: {
              id: point.id,
              name: point.name,
            },
            additionalData: {
              typeId,
            },
          };
        })
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    }
  },

  /**
   * Pares que vem das categorias, sintomas e patógenos
   * @param state
   * @param item
   * @returns {Promise<*>}
   */
  async [_ACTIONS.STEPS_OTHER_PROPERTIES]({ state }, item) {
    const propertyMapToFindParesBySlug = new Map([
      ["category", () => categoriesService.findOne(item.id)],
      ["symptom", () => symptomsService.findOne(item.id)],
      ["pathogen", () => pathogensService.findOne(item.id)],
    ]);

    const functionToGetPopulatedProperty = propertyMapToFindParesBySlug.get(
      item.property.slug
    );

    const result = await functionToGetPopulatedProperty();

    const pointsOrderOfProperty = await pointsOrderService.findByIds(
      result.pares.map((par) => par.points.id)
    );

    const promiseToPopulatePoints = pointsOrderOfProperty.map(
      async (pointOrder) => {
        return {
          trackingPoint: await pointsService.findOne(
            pointOrder.trackingPointId
          ),
          impactionPoint: await pointsService.findOne(
            pointOrder.impactionPointId
          ),
          ...pointOrder,
        };
      }
    );

    const pointsOrder = await Promise.all(promiseToPopulatePoints);

    const { isSecondLevel: isParSecondLevel } = item;

    return _(pointsOrder)
      .groupBy((pointOrder) => {
        return pointOrder.trackingPointId + "-" + pointOrder.par.typeId;
      })
      .map((pointsOrder) => {
        return {
          title: isParSecondLevel
            ? pointsOrder[0].par.name
            : pointsOrder[0].trackingPoint.name,
          slug: SlugEnum.PARES_ADICIONADOS_POR_PROPRIEDADES,
          trackingPoint: pointsOrder[0].trackingPoint,
          impactionPoints: pointsOrder.map((pointOrder) => ({
            pointOrderId: pointOrder.id,
            ...pointOrder.impactionPoint,
          })),
        };
      })
      .value();
  },
  async [_ACTIONS.OPEN_DETAIL_DIALOG]({ commit, dispatch }, step) {
    dispatch("loading/openDialog", null, { root: true });
    const mapDialogs = new Map([
      [
        SlugEnum.FENOMENOS_TUMORAIS,
        (step) => {
          dispatch("tumoralPhenomena/selectTumoralPhenomenon", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.FENOMENOS_VASCULARES,
        (step) => {
          dispatch("vascularPhenomena/selectOne", step.type, { root: true });
        },
      ],
      [
        SlugEnum.ESPACOS_POTENCIAIS,
        (step) => {
          dispatch("potentialSpaces/selectOne", step.type, { root: true });
        },
      ],
      [
        SlugEnum.TIPOS_DE_FLUXO,
        (step) => {
          dispatch("flowTypes/selectOne", step.type, { root: true });
        },
      ],
      [
        SlugEnum.OLIGOELEMENTOS,
        (step) => {
          dispatch("oligoelements/selectType", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.CHAKRAS,
        (step) => {
          dispatch("chakras/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.CROMOSSOMOS,
        (step) => {
          dispatch("chromosomes/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.PARES_PSICOEMOCIONAIS,
        (step) => {
          dispatch("psycoemotionals/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.INTOXICACAO,
        (step) => {
          dispatch("poisoning/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.HORMONAIS,
        (step) => {
          dispatch("hormonal/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.ESPIRITUAIS_E_MALIGNOS,
        (step) => {
          dispatch("spiritual/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.RASTREIO,
        (step) => {
          commit("points/setSelectedTypeId", step.additionalData.typeId, {
            root: true,
          });
          commit("points/setSelectedTrackingPoint", step.trackingPoint, {
            root: true,
          });

          dispatch("points/loadImpactionPointsWithPar", null, { root: true });
        },
      ],
      [
        SlugEnum.RESERVATORIO,
        (step) => {
          console.log(step.trackingPoint);
          commit("points/setSelectedTypeId", step.additionalData.typeId, {
            root: true,
          });
          commit("points/setSelectedTrackingPoint", step.trackingPoint, {
            root: true,
          });

          dispatch("points/loadImpactionPointsWithPar", null, { root: true });
        },
      ],
      [
        SlugEnum.PARES_ADICIONADOS_POR_PROPRIEDADES,
        (step) => {
          commit("points/setSelectedTrackingPoint", step.trackingPoint, {
            root: true,
          });

          commit("points/setImpactionPoints", step.impactionPoints, {
            root: true,
          });
          commit("dialogs/setParesImpactionPoints", true, { root: true });
        },
      ],
      [
        SlugEnum.TERAPIAS_COMPLEMENTARES,
        (step) => {
          dispatch("complementaryTherapies/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.SOFROLOGICO,
        (step) => {
          dispatch("sophrological/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.HERPES_CHECAGEM,
        (step) => {
          dispatch("herpesCheck/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.MOVIMENTO_3D1_3D2,
        (step) => {
          dispatch("movement3D12/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.PROTOCOLO_BASICO,
        (step) => {
          dispatch("basicProtocol/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.PROTOCOLO_LINFATICO,
        (step) => {
          dispatch("lymphaticProtocol/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.PROTOCOLO_FSMI,
        (step) => {
          dispatch("fsmiProtocol/selectOne", step.type, {
            root: true,
          });
        },
      ],
      [
        SlugEnum.PARES_ESPECIAIS_NIVEL_1_2,
        (step) => {
          dispatch("specialPairs/selectOne", step.type, {
            root: true,
          });
        },
      ],
    ]);

    const showDialog = mapDialogs.get(step.slug);
    await showDialog(step);

    dispatch("loading/closeDialog", null, { root: true });
  },
};
