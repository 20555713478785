<template>
  <v-btn
    block
    large
    color="secondary"
    :disabled="isAddedToDiagnose"
    :height="$vuetify.breakpoint.smAndUp ? 50 : 50"
    @click="$emit('click')"
  >
    <v-icon class="mr-2">add_circle</v-icon>
    <span v-if="isAddedToDiagnose">
      Já adicionado ao <br v-if="$vuetify.breakpoint.xs" />diagnóstico</span
    >
    <span v-else>Adicionar ao Diagnóstico</span>
  </v-btn>
</template>

<script>
export default {
  name: "AddToDiagnoseButton",
  props: {
    isAddedToDiagnose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
