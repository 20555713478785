<template>
  <div class="d-inline-block">
    <v-btn icon @click="open">
      <tooltip-icon icon="help_outline" :text="tooltipText" color="primary" />
    </v-btn>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <back-button color="grey" @click="close" />
          <span>
            Explicação sobre: <strong>{{ name }}</strong>
          </span>
        </v-card-title>
        <v-card-text class="body-1">
          <template v-if="!$slots.default">
            <p v-if="explanation">{{ explanation }}</p>
            <empty-list v-else text="Nenhuma explicação para este tipo." />
          </template>
          <slot v-else name="default" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ExplanationIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      default: "Explicação",
    },
    explanation: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
