export const state = {
  selectedItems: [],
  sequence: [],
  dialogs: {
    tumoralPhenomena: false,
    impactionPoints: false,
  },
  indexSelectedProperty: 0,
  hasUnhandledType: false,
};
