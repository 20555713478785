import Vue from "vue";
import i18n from "@/plugins/i18n";
import { isValidCpf } from "@brazilian-utils/validators";
import moment from "moment";

Vue.prototype.$rules = {
  required: (v) =>
    (v !== null && v !== undefined && v !== "") || i18n.t("rules.required"),
  email: (v) => /.+@.+/.test(v) || i18n.t("rules.email"),
  confirmPassword(password1, password2) {
    return () => password1 === password2 || i18n.t("rules.matchPassword");
  },
  minLengthPassword: (v) =>
    (!!v && v.length >= 6) || i18n.t("rules.minLengthPassword"),
  cpf: (v) => (!!v && isValidCpf(v)) || i18n.t("rules.cpf"),
  date: (v) => {
    const dateMoment = moment(v, "DD/MM/YYYY", true);
    return (
      (!!v && dateMoment.isValid() && dateMoment.year() > 1900) ||
      i18n.t("rules.date")
    );
  },
  year: (v) =>
    (parseInt(v, 10) && v > 1900 && v < new Date().getFullYear()) ||
    i18n.t("rules.invalidYear"),
  arrayNotEmpty: (array) =>
    (array && array.length > 0) || i18n.t("rules.arrayNotEmpty"),
  maxLengthVarchar: (string) =>
    string.length <= 255 || i18n.t("rules.tooLongText"),
};
