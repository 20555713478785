import {
  getAppointmentByIdToConfirmation,
  getSlotsDayByTherapist,
  getSlotsTimeByAppointment,
  savePatientToAppointment,
} from "@/services/external/appointments-service";
import { verifyPatientAbleToAppoint } from "@/services/external/users-service";

const initialState = () => ({
  step: 1,
  selectedTherapist: {},
  slotDays: [],
  selectedDate: "",
  slotTimes: [],
  selectedTime: {},
  selectedAppointmentId: "",
  dialogFormUserData: false,
});

const state = initialState();

const mutations = {
  nextStep(state) {
    state.step++;
  },
  previousStep(state) {
    state.step--;
  },
  selectTherapist(state, value) {
    state.selectedTherapist = value;
  },
  setSlotDays(state, value) {
    state.slotDays = value;
  },
  selectDate(state, date) {
    state.selectedDate = date;
  },
  setSlotTimes(state, times) {
    state.slotTimes = times;
  },
  selectTime(state, { startAt, endAt }) {
    state.selectedTime = { startAt, endAt };
  },
  selectAppointmentId(state, value) {
    state.selectedAppointmentId = value;
  },
  clearState(state) {
    Object.assign(state, initialState());
  },
  setDialogFormUserData(state, value) {
    state.dialogFormUserData = value;
  },
  setDataFromWebsite(
    state,
    { selectedTherapist, startAt, endAt, selectedAppointmentId }
  ) {
    state.selectedTherapist = selectedTherapist;
    state.selectedDate = startAt;
    state.selectedTime = { startAt, endAt };
    state.selectedAppointmentId = selectedAppointmentId;
    state.step = 4;
  },
};

const actions = {
  async selectTherapist({ commit, state }, therapist) {
    commit("selectTherapist", therapist);
    const { therapistId } = therapist;
    const { data } = await getSlotsDayByTherapist(therapistId);
    commit("setSlotDays", data);
    commit("nextStep");
  },
  async selectDate({ commit, state }, { date, appointmentId }) {
    commit("selectDate", date);
    const { data } = await getSlotsTimeByAppointment(appointmentId);
    commit("setSlotTimes", data);
    commit("nextStep");
  },
  async selectTime({ commit }, { startAt, endAt, appointmentId }) {
    commit("selectTime", { startAt, endAt });
    commit("selectAppointmentId", appointmentId);
    commit("nextStep");
  },
  async finish({ state, commit }) {
    await savePatientToAppointment(state.selectedAppointmentId);
    commit("clearState");
  },
  async isPatientAbleToAppoint({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        await verifyPatientAbleToAppoint();
        resolve();
      } catch (error) {
        commit("setDialogFormUserData", true);
        reject(error);
      }
    });
  },
  async fillDataFromWebsite({ commit }, appointmentId) {
    const { data } = await getAppointmentByIdToConfirmation(appointmentId);
    const { selectedTherapist, startAt, endAt, selectedAppointmentId } = data;
    commit("setDataFromWebsite", {
      selectedTherapist,
      startAt,
      endAt,
      selectedAppointmentId,
    });
  },
};

const getters = {
  hasTherapist(state) {
    return !!state.selectedTherapist.therapistId;
  },
  hasDate(state) {
    return !!state.selectedDate;
  },
  hasTime(state) {
    return !!state.selectedTime.startAt;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
