import db from "@/database";

export const findAll = () => {
  return db.category.orderBy("name").toArray();
};

export const findOne = (id) => {
  return db.category.get(id);
};

export const findByIds = (ids) => {
  return db.category.where("id").anyOf(ids).toArray();
};
