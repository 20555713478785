<template>
  <v-card elevation="0" :class="{ 'rounded-20': !squared }">
    <v-card-title v-if="title">
      <back-button
        v-if="renderBackButton"
        color="grey"
        @click="$emit('click-back')"
      />
      <div
        :class="{
          'd-flex ': true,
          'flex-column': true,
          'align-center': titleCenter,
          'full-width': titleCenter,
        }"
      >
        {{ title }}
        <span v-if="subtitle" :class="`subtitle-2 ${classesSubtitle}`">
          {{ subtitle }}
        </span>
      </div>
      <slot v-if="$slots.additionalTitle" name="additionalTitle" />
    </v-card-title>
    <v-divider v-if="titleDivider" class="mx-5" />
    <v-card-text>
      <slot name="default" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NewPerseuCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    titleCenter: {
      type: Boolean,
      default: false,
    },
    titleDivider: {
      type: Boolean,
      default: false,
    },
    renderBackButton: {
      type: Boolean,
      default: false,
    },
    classesSubtitle: {
      type: String,
      default: "",
    },
    squared: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
