import db from "@/database";
import { TherapyTemplateItemEnum } from "@/domains/therapy/steps/mount-sequence/domain/therapy-template-item-enum";

export const getAll = () => db.therapyTemplates.orderBy("createdAt").toArray();

export const getPropertyObjectByTemplateItem = (
  therapyTemplateItemProperty
) => {
  const mapConditions = new Map([
    [
      TherapyTemplateItemEnum.CATEGORY,
      { name: "Categorias", slug: "category" },
    ],
    [TherapyTemplateItemEnum.PATHOGEN, { name: "Patógenos", slug: "pathogen" }],
    [TherapyTemplateItemEnum.SYMPTOM, { name: "Sintomas", slug: "symptom" }],
    [TherapyTemplateItemEnum.TYPE, { name: "Tipos", slug: "type" }],
  ]);

  return mapConditions.get(therapyTemplateItemProperty);
};
