import therapyTemplatesModule from "@/domains/therapy/steps/mount-sequence/store/therapy-templates.module";

import { state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    therapyTemplates: therapyTemplatesModule,
  },
};
