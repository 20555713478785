const DURATION_BASE = 500000;

const state = {
  selectedSpeed: 1,
  settingsDialog: false,
};

const mutations = {
  setSelectedSpeed(state, value) {
    state.selectedSpeed = value;
  },
  setSettingsDialog(state, value) {
    state.settingsDialog = value;
  },
};

const actions = {};

const getters = {
  duration(state) {
    return DURATION_BASE / state.selectedSpeed;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
