<template>
  <v-btn
    large
    elevation="0"
    rounded
    color="primary"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot name="default" />
  </v-btn>
</template>

<script>
import VBtn from "vuetify/lib/components/VBtn";
export default {
  name: "ParButton",
  props: {
    ...VBtn.options.props,
  },
};
</script>

<style scoped></style>
