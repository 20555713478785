import { findByPar } from "@/services/local/points-order-service";
import { findOne } from "@/services/local/points-service";
import { findOne as findOneType } from "@/services/local/types-service";

const state = {
  selected: {
    points: {},
  },
};

const mutations = {
  setSelected(state, value) {
    state.selected = value;
  },
};

const actions = {
  async selectOne({ commit }, par) {
    const { trackingPointId, impactionPointId } = await findByPar(par.id);

    const populatedWithPoints = {
      ...par,
      type: await findOneType(par.typeId),
      points: {
        trackingPoint: await findOne(trackingPointId),
        impactionPoint: await findOne(impactionPointId),
      },
    };

    commit("setSelected", populatedWithPoints);
    commit("dialogs/setPoisoning", true, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
