export const parToDiagnoseParMapper = ({
  par,
  pathogens,
  symptoms,
  hidePatient,
  patientImpactAtHome,
  trackingPointSide,
  impactionPointSide,
  patientComments,
  therapistComments,
}) => ({
  par: {
    id: par.id,
    name: par.name,
    type: { name: par.type.name },
    categories: par.categories,
  },
  pathogens,
  symptoms,
  hidePatient,
  patientImpactAtHome,
  trackingPointSide,
  impactionPointSide,
  patientComments,
  therapistComments,
});
