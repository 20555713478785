import { findByIds, findOne } from "@/services/local/points-order-service";
import { findOne as findOnePoint } from "@/services/local/points-service";
import { checkParesButtonsMapper } from "@/domains/therapy/steps/execute/domains/check-pares/mappers/check-pares-buttons.mapper";
import { selectedParToDiagnoseMapper } from "@/domains/therapy/steps/execute/domains/pares/mappers/selected-par-to-diagnose.mapper";
import { parToDiagnoseParMapper } from "@/domains/therapy/steps/diagnose/mappers/par-to-diagnose-par.mapper";

const diagnoseParInitialState = {
  par: {},
  comments: {},
  pointsSides: {
    trackingPointSide: "",
    impactionPointSide: "",
  },
  symptoms: [],
  pathogens: [],
  hidePatient: false,
  patientImpactAtHome: false,
};

const getInitialState = () => ({
  selectedPar: {
    type: {},
  },
  checkPar: {
    type: {},
  },
  checkParesHistory: [],
  alreadyCheckedParesOnlyIds: [],
  diagnosePar: diagnoseParInitialState,
});

const state = getInitialState();

const mutations = {
  clearState(state) {
    Object.assign(state, getInitialState());
  },
  changeSelectedPar(state, par) {
    state.selectedPar = par;
  },
  clearSelectedPar(state) {
    state.selectedPar = {
      type: {},
    };
  },
  setCheckPar(state, par) {
    state.checkPar = par;
  },
  addCheckParHistory(state, checkPar) {
    state.checkParesHistory.push(checkPar);
  },
  removeLastCheckParHistory(state) {
    state.checkParesHistory.pop();
  },
  clearCheckParesHistory(state) {
    state.checkParesHistory = [];
  },
  addParToAlreadyChecked(state, pointOrderId) {
    !state.alreadyCheckedParesOnlyIds.includes(pointOrderId) &&
      state.alreadyCheckedParesOnlyIds.push(pointOrderId);
  },
  clearDiagnosePar(state) {
    state.diagnosePar = diagnoseParInitialState;
  },
  setDiagnosePar(state, diagnosePar) {
    state.diagnosePar = diagnosePar;
  },
  setValuesDiagnosePar(state, { value, property }) {
    state.diagnosePar = { ...state.diagnosePar, [property]: value };
  },
};

const actions = {
  clearState({ commit }) {
    commit("clearState");
  },
  async selectPar({ commit, dispatch }, pointOrderId) {
    dispatch("loading/openDialog", null, { root: true });
    commit("clearCheckParesHistory");
    commit("clearSelectedPar");
    commit("clearDiagnosePar");

    const pointOrder = await findOne(pointOrderId);

    const par = pointOrder.par;
    if (par.checkPointsOrder && par.checkPointsOrder.length > 0) {
      const pointsOrderIds = pointOrder.par.checkPointsOrder.map(
        (checkPo) => checkPo.id
      );
      par.checkPares = await dispatch("populateCheckPares", pointsOrderIds);
      delete par.checkPointsOrder;
    }

    await commit("changeSelectedPar", {
      ...par,
      pointOrderId,
      points: {
        trackingPoint: await findOnePoint(pointOrder.trackingPointId),
        impactionPoint: await findOnePoint(pointOrder.impactionPointId),
      },
    });
    commit("dialogs/setParDialog", true, { root: true });
    dispatch("loading/closeDialog", null, { root: true });
  },
  async populateCheckPares({ commit, getters, rootGetters }, pointsOrderIds) {
    const pointsOrderToCheck = await findByIds(pointsOrderIds);

    return checkParesButtonsMapper(pointsOrderToCheck);
  },
  async addDiagnoseParToDiagnose({ dispatch, state, commit }) {
    const {
      pathogens,
      symptoms,
      pointsSides: { trackingPointSide, impactionPointSide },
      comments: { patientComments, therapistComments },
      hidePatient,
      patientImpactAtHome,
    } = state.diagnosePar;
    await dispatch(
      "diagnosis/addParToDiagnose",
      parToDiagnoseParMapper({
        par: state.selectedPar,
        pathogens,
        symptoms,
        hidePatient,
        patientImpactAtHome,
        trackingPointSide,
        impactionPointSide,
        patientComments,
        therapistComments,
      }),
      { root: true }
    );
    await dispatch(
      "points/changeFieldAddedToDiagnosis",
      {
        parId: state.selectedPar.id,
        isAdded: true,
      },
      { root: true }
    );
  },
  async selectCheckPar({ commit, dispatch }, pointOrderId) {
    dispatch("loading/openDialog", null, { root: true });
    const pointOrder = await findOne(pointOrderId);

    const pointsOrderIds = pointOrder.par.checkPointsOrder.map(
      (checkPo) => checkPo.id
    );
    const { trackingPointId, impactionPointId, par } = pointOrder;
    par.checkPares = await dispatch("populateCheckPares", pointsOrderIds);

    const checkPar = {
      ...par,
      points: {
        trackingPoint: await findOnePoint(trackingPointId),
        impactionPoint: await findOnePoint(impactionPointId),
      },
    };

    commit("addParToAlreadyChecked", pointOrderId);
    commit("setCheckPar", checkPar);
    commit("addCheckParHistory", checkPar);
    dispatch("loading/closeDialog", null, { root: true });
  },
  backCheckParHistory({ commit, state, dispatch }) {
    dispatch("loading/openDialog", null, { root: true });
    const lastParChecked =
      state.checkParesHistory[state.checkParesHistory.length - 2];
    commit("setCheckPar", lastParChecked);
    commit("removeLastCheckParHistory");
    dispatch("loading/closeDialog", null, { root: true });
  },
  async editParAlreadyAddedToDiagnose({ rootState, dispatch, commit }, parId) {
    const parInDiagnose = rootState.diagnosis.diagnose.pares.find(
      (diagnosePar) => diagnosePar.par.id === parId
    );
    commit("changeSelectedPar", parInDiagnose);
    commit("dialogs/setParDialog", true, { root: true });
  },
};

const getters = {
  selectedPar(state) {
    return state.selectedPar;
  },
  selectedPoints(state) {
    return state.points;
  },
  existsParToBack(state) {
    return state.checkParesHistory.length > 1;
  },
  alreadyCheckedParesOnlyIds(state) {
    return state.alreadyCheckedParesOnlyIds;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
