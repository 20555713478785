const state = {
  selected: {},
};

const mutations = {
  setSelected(state, value) {
    state.selected = value;
  },
};

const actions = {
  selectOne({ commit }, therapy) {
    console.log("selectOne", therapy);
    commit("setSelected", therapy);
    commit("dialogs/setComplementaryTherapy", true, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
