<template>
  <v-tooltip :value="tooltipValue" left>
    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          fixed
          color="secondary"
          fab
          large
          dark
          bottom
          right
          style="bottom: 10px !important"
          v-on="on"
          @click="$emit('click')"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <span class="subtitle-1">{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "FixedButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tooltipValue: true,
  }),
  mounted() {
    setTimeout(() => (this.tooltipValue = false), 2000);
  },
};
</script>

<style scoped></style>
