import { findAll } from "@/services/local/potential-spaces-service.js";
import { transformCategoriesToString } from "@/utils/categories";

const state = {
  potentialSpaces: [],
  selected: {},
};

const mutations = {
  setPotentialSpaces(state, value) {
    state.potentialSpaces = value;
  },
  setSelected(state, value) {
    state.selected = value;
  },
};

const actions = {
  async load({ commit }) {
    const response = await findAll();
    commit("setPotentialSpaces", response);
  },
  selectOne({ commit, dispatch }, value) {
    commit("setSelected", value);
    dispatch(
      "resolutionZones/showDialog",
      {
        returnAction: "potentialSpaces/handleReturnResolutionZone",
        validateAction: "potentialSpaces/verifyIfAlreadyAdded",
      },
      { root: true }
    );
  },
  async handleReturnResolutionZone(
    { state, commit, dispatch },
    {
      therapistComments,
      patientComments,
      resolutionZone,
      affectedSpotParTrauma,
      trackingPointSide,
      impactionPointSide,
    }
  ) {
    const title = "Checar associações patogênicas";
    const message = `Recomendamos que o sejam checadas as associações patogências deste espaço potencial.
    
    Associações patogênicas: ${transformCategoriesToString(
      state.selected.categories
    )}
    
    Instruções: ${state.selected.instructions}
    
    Deseja checar?`;

    const responseCheckCategories = await dispatch(
      "settings/openConfirm",
      { title, message },
      { root: true }
    );

    const diagnose = {
      potentialSpace: state.selected,
      resolutionZone,
      therapistComments,
      patientComments,
      checkedCategories: responseCheckCategories,
      diagnoseResolutionZone: {
        resolutionZone,
        affectedSpotParTrauma,
        trackingPointSide,
        impactionPointSide,
      },
    };

    commit("diagnosis/addPotentialSpace", diagnose, { root: true });

    if (responseCheckCategories) {
      const ids = state.selected.categories.map((category) => category.id);
      dispatch("checkCategories/startByIds", ids, { root: true });
    }
  },
  verifyIfAlreadyAdded: function ({ state, rootState }, { resolutionZone }) {
    return new Promise((resolve, reject) => {
      const exists = rootState.diagnosis.diagnose.potentialSpaces.some(
        (diagnose) =>
          diagnose.potentialSpace.id === state.selected.id &&
          diagnose.resolutionZone.id === resolutionZone.id
      );
      if (exists) {
        reject({
          message:
            "Combinação de Espaço Potencial e Zona de Resolução já adicionado ao diagnóstico.",
        });
      }
      resolve();
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
