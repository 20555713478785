import "@babel/polyfill";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/registerServiceWorker";
import "@/components/shared";
import "@/utils/filters";
import i18n from "@/plugins/i18n";
import "@/plugins";

import flags from "vue-flag-icon";
import "@/misc/rules";
import "@/misc/errors";
import "@/styles/custom.css";
import "@/database";
import "leaflet/dist/leaflet.css";
import vueJsDriver from "vue-js-driver";
import driverConfig from "@/config/driver-config.js";

Vue.use(vueJsDriver, driverConfig);
Vue.use(flags);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import "@/styles/main.scss";
