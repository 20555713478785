import db from "@/database";
import { syncAll } from "@/services/external/sync-service";
import dayjs from "dayjs";
import { getLastConfig } from "@/services/local/config-service";
import { downloadConsentFormFromAws } from "@/services/external/aws-service";
import { CONSENT_FILE_ID } from "@/services/local/settings-service";

const state = {
  dialog: false,
};

const mutations = {
  changeDialog(state, value) {
    state.dialog = value;
  },
  forceCloseDialog(state) {
    state.dialog = false;
  },
};

const actions = {
  async download({ commit, dispatch }) {
    commit("changeDialog", true);
    return new Promise(async (resolve, reject) => {
      const therapys = await db.therapy.toArray();
      const { data } = await syncAll();
      await db.delete();
      await db.open();

      console.log("data", data.complementaryTherapies);
      await Promise.all([
        db.category.bulkAdd(data.categories),
        db.type.bulkAdd(data.types),
        db.symptom.bulkAdd(data.symptoms),
        db.pathogen.bulkAdd(data.pathogens),
        db.sequence.bulkAdd(data.sequences),
        db.par.bulkAdd(data.pares),
        db.point.bulkAdd(data.points),
        db.pointsOrder.bulkAdd(data.pointsOrder),
        db.maritalStatus.bulkAdd(data.maritalStatus),
        db.tumoralPhenomenon.bulkAdd(data.tumoralPhenomena),
        db.resolutionZone.bulkAdd(data.resolutionZones),
        db.vascularPhenomenon.bulkAdd(data.vascularPhenomena),
        db.potentialSpace.bulkAdd(data.potentialSpaces),
        db.flowType.bulkAdd(data.flowTypes),
        db.oligoelementsType.bulkAdd(data.oligoelementsTypes),
        db.chakra.bulkAdd(data.chakras),
        db.chromosome.bulkAdd(data.chromosomes),
        db.therapyTemplates.bulkAdd(data.therapyTemplates),
        db.complementaryTherapy.bulkAdd(data.complementaryTherapies),
        dispatch("checkAndDownloadConsentTermFile", data.consentTermUrl),
        dispatch("downloadImages", data.points),

        db.config.add({
          lastUpdate: new Date(),
        }),
        db.therapy.bulkAdd(therapys),
      ])
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          commit("changeDialog", false);
        });
      resolve();
    });
  },
  async verifyIfNeedsUpdate({ dispatch }) {
    if (!navigator.onLine) return false;
    try {
      const config = await getLastConfig();
      const diff = dayjs(config.lastUpdate).diff(dayjs(), "day");
      if (diff <= -1) return true;
    } catch (error) {
      return true;
    }
    return false;
  },
  async verifyIfNeedsUpdateAndDownload({ dispatch }) {
    if (await dispatch("verifyIfNeedsUpdate")) {
      dispatch("download");
    }
  },
  async checkAndDownloadConsentTermFile({}, consentTermUrl) {
    if (consentTermUrl) {
      const consentTermFile = await downloadConsentFormFromAws(consentTermUrl);
      db.settings.add({
        id: CONSENT_FILE_ID,
        consentTermUrl: consentTermUrl || "",
        consentTerm: consentTermFile,
      });
    }
  },
  async downloadImages({}, points) {
    //  return;
    const imageUrls = points.map((point) => point.image);
    const downloadedImages = [];

    for (const imageUrl of imageUrls) {
      if (imageUrl && imageUrl.startsWith("https")) {
        try {
          const image = await downloadConsentFormFromAws(imageUrl);
          downloadedImages.push({
            url: imageUrl,
            blob: image,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    await db.images.bulkAdd(downloadedImages);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
