<template>
  <div>
    <span class="font-weight-bold">Recomendações: </span>
    <span v-if="recommendations" class="black--text">
      {{ recommendations }}
    </span>
    <span v-else class="grey--text"> Nenhuma recomendação para esse par. </span>
  </div>
</template>

<script>
export default {
  props: {
    recommendations: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
