import i18n from "@/plugins/i18n";

const state = {
  lang: JSON.parse(localStorage.getItem("lang")),
  langs: [
    { value: "pt-BR", text: "Português (Brasil)", flag: "br" },
    { value: "en", text: "English", flag: "us" },
    { value: "es", text: "Español", flag: "es" },
  ],
};

const mutations = {
  setLang(state, lang) {
    state.lang = lang;
  },
};

const actions = {
  set(context, lang) {
    i18n.locale = lang.value;
    localStorage.setItem("lang", JSON.stringify(lang));
    context.commit("setLang", lang);
  },
  setBrowserLanguage({ state, dispatch }) {
    const userLang = navigator.language || navigator.userLanguage;
    const lang = state.langs.filter((lang) => lang.value === userLang)[0];
    dispatch("set", userLang && lang ? lang : state.langs[0]);
  },
};

const getters = {
  setted(state) {
    return state.lang;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
