import Vue from "vue";
import PerseuCard from "./PerseuCard";
import NewPerseuCard from "./NewPerseuCard";
import DialogCard from "./DialogCard";
import AutoRoll from "./AutoRoll";
import PerseuTableActionButton from "./PerseuTableActionButton";
import TextWithEditor from "./TextWithEditor";
import SelectItemsWithEditor from "./SelectItemsWithEditor";
import SwitchWithEditor from "./SwitchWithEditor";
import LabelIcon from "./LabelIcon";
import SelectParProperties from "./SelectParProperties";
import InputDate from "./InputDate";
import BackButton from "./BackButton";
import ConfirmDialog from "./ConfirmDialog";
import TooltipIcon from "./TooltipIcon";
import EnvChip from "./EnvChip";
import Recommendations from "./Recommendations";
import ViewPoint from "./ViewPoint";
import ViewTrackingAndImpactionPoint from "./ViewTrackingAndImpactionPoint";
import PropertyValue from "./PropertyValue";
import ImageSection from "./ImageSection";
import ReviewCard from "./ReviewCard";
import ImageExpansion from "./ImageExpansion";
import TimePicker from "./TimePicker";
import EmptyList from "@/components/shared/EmptyList";
import SectionTitle from "@/components/shared/SectionTitle";
import WarningSection from "@/components/shared/WarningSection";
import TrackingImpactionPointInputSides from "@/components/shared/TrackingImpactionPointInputSides";
import TrackingImpactionPointViewSides from "@/components/shared/TrackingImpactionPointViewSides";
import StepButtonsTherapy from "@/components/shared/StepButtonsTherapy";
import FixedButton from "@/components/shared/FixedButton";
import ExplanationIcon from "@/components/shared/ExplanationIcon";
import ParButton from "@/components/shared/ParButton";
import TherapyCard from "@/components/shared/TherapyCard";
import CardTitleWithBackButton from "@/components/shared/CardTitleWithBackButton";
import AddToDiagnoseButton from "@/components/shared/AddToDiagnoseButton";

Vue.component("new-perseu-card", NewPerseuCard);
Vue.component("perseu-card", PerseuCard);
Vue.component("dialog-card", DialogCard);
Vue.component("auto-roll", AutoRoll);
Vue.component("table-action-button", PerseuTableActionButton);
Vue.component("text-editor", TextWithEditor);
Vue.component("select-items-editor", SelectItemsWithEditor);
Vue.component("switch-editor", SwitchWithEditor);
Vue.component("label-icon", LabelIcon);
Vue.component("select-par-properties", SelectParProperties);
Vue.component("input-date", InputDate);
Vue.component("back-button", BackButton);
Vue.component("confirm-dialog", ConfirmDialog);
Vue.component("tooltip-icon", TooltipIcon);
Vue.component("env-chip", EnvChip);
Vue.component("recommendations", Recommendations);
Vue.component("view-point", ViewPoint);
Vue.component("view-tracking-impaction-point", ViewTrackingAndImpactionPoint);
Vue.component("property-value", PropertyValue);
Vue.component("image-section", ImageSection);
Vue.component("review-card", ReviewCard);
Vue.component("image-expansion", ImageExpansion);
Vue.component("time-picker", TimePicker);
Vue.component("empty-list", EmptyList);
Vue.component("section-title", SectionTitle);
Vue.component("warning-section", WarningSection);
Vue.component(
  "tracking-impaction-point-input-sides",
  TrackingImpactionPointInputSides
);
Vue.component(
  "tracking-impaction-point-view-sides",
  TrackingImpactionPointViewSides
);
Vue.component("step-buttons-therapy", StepButtonsTherapy);
Vue.component("fixed-button", FixedButton);
Vue.component("explanation-icon", ExplanationIcon);
Vue.component("par-button", ParButton);
Vue.component("therapy-card", TherapyCard);
Vue.component("card-title-with-back-button", CardTitleWithBackButton);
Vue.component("add-to-diagnose-button", AddToDiagnoseButton);
