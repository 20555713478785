<template>
  <div class="mb-3 body-2 grey--text text--darken-3 d-flex flex-wrap root">
    <v-icon v-if="icon" color="secondary" class="mr-2">{{ icon }}</v-icon>
    <span class="font-weight-bold mr-1">{{ property }}: </span>
    <pre v-if="!$slots.value" style="white-space: pre-wrap" v-text="value" />
    <slot v-else name="value" />
  </div>
</template>

<script>
export default {
  name: "PropertyValue",
  props: {
    property: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.root {
  line-height: 20px;
}
</style>
