/**
 * Slugs são gerenciados pelo backend (somente para tipos) e este ENUM é uma representação do backend no frontend,
 * portanto sempre que um novo SLUG for criado (tipos) o mesmo deve ser adicionado aqui.
 */
export const SlugEnum = {
  FENOMENOS_TUMORAIS: "fenomenos-tumorais",
  FENOMENOS_VASCULARES: "fenomenos-vasculares",
  ESPACOS_POTENCIAIS: "espacos-potenciais",
  TIPOS_DE_FLUXO: "tipos-de-fluxo",
  OLIGOELEMENTOS: "oligoelementos",
  CHAKRAS: "chakras",
  CROMOSSOMOS: "cromossomos",
  PARES_PSICOEMOCIONAIS: "pares-psicoemocionais",
  INTOXICACAO: "intoxicacaoenvenenamento",
  HORMONAIS: "hormonais",
  ESPIRITUAIS_E_MALIGNOS: "espirituais-e-malignos",
  RASTREIO: "rastreio",
  RESERVATORIO: "reservatorio",
  // Este enum não é gerenciado pelo backend, mas sim criado no código para melhor gerenciamento na terpia
  PARES_ADICIONADOS_POR_PROPRIEDADES: "pares-adicionados-por-propriedades",
  TERAPIAS_COMPLEMENTARES: "terapias-complementares",
  SOFROLOGICO: "sofrologico",
  HERPES_CHECAGEM: "herpes-checagem",
  MOVIMENTO_3D1_3D2: "movimento-3d1e-3d2",
  PROTOCOLO_BASICO: "protocolo-basico",
  PROTOCOLO_LINFATICO: "protocolo-linfatico",
  PROTOCOLO_FSMI: "protocolo-fsmi",
  PARES_ESPECIAIS_NIVEL_1_2: "pares-especiais-nivel-1-e-2",
};
