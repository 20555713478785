import api from "./api-service";
import { findNotSynced } from "@/services/local/therapies-service";
import { TherapyStatusEnum } from "@/domains/therapy/shared/application/therapy-status.enum";

const url = "therapys";

const findByTherapist = async () => {
  const offline = await findNotSynced();
  if (navigator.onLine) {
    const offlineFormatted = offline.map((therapy) => ({
      patientName: therapy.patient?.name,
      realizedAt: therapy.createdAtLocal,
      status: therapy.status,
      therapy,
    }));
    const { data: online } = await api().get(`${url}/therapist`);
    return [...offlineFormatted, ...online];
  } else {
    return offline;
  }
};

const findByLoggedUserPatient = () => {
  return api().get(url + "/patient");
};

const findOnePatient = (id) => {
  return api().get(`${url}/${id}/patient`);
};

const findOneTherapist = (protocol) => {
  return api().get(`${url}/${protocol}/therapist`);
};

const getTherapyAttachments = (protocol) => {
  return api().get(`${url}/${protocol}/attachments`);
};

export const findLastTherapyQuestions = (userEmail) => {
  return api().get(`${url}/last-therapy-with-questions?userEmail=${userEmail}`);
};

const create = (obj) => {
  return api().post(url, obj);
};

const sendToQueue = (therapy) => api().post();

export {
  findByTherapist,
  create,
  findByLoggedUserPatient,
  findOnePatient,
  findOneTherapist,
  getTherapyAttachments,
};

export const updateTherapyRating = (therapyId, rating) =>
  api().patch(`${url}/${therapyId}/rating`, { therapistRating: rating });
