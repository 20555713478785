export class DiagnoseTumoralPhenomena {
  constructor({
    tumoralPhenomenon,
    checkedCategories,
    relatedPares,
    diagnoseResolutionZone,
    therapistComments,
    patientComments,
  }) {
    this.tumoralPhenomenon = tumoralPhenomenon;
    this.checkedCategories = checkedCategories;
    this.relatedPares = relatedPares;
    this.diagnoseResolutionZone = diagnoseResolutionZone;
    this.therapistComments = therapistComments;
    this.patientComments = patientComments;
  }
}
