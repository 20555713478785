import { findAll, findOne } from "@/services/local/flow-types-service";
import { findByIds } from "@/services/local/points-order-service";
import { findOne as findOnePoint } from "@/services/local/points-service";

const state = {
  flowTypes: [],
  selected: {},
};

const mutations = {
  setFlowTypes(state, value) {
    state.flowTypes = value;
  },
  replaceFlowType(state, { index, value }) {
    state.flowTypes.splice(index, 1, value);
  },
  setSelected(state, flowType) {
    state.selected = flowType;
  },
  clearSelected(state) {
    state.selected = {};
  },
};

const actions = {
  async load({ commit }) {
    const result = await findAll();
    commit("setFlowTypes", result);
  },
  async selectOne({ commit, state, dispatch }, { id }) {
    dispatch("loading/openDialog", null, { root: true });
    dispatch("populatePointsOrder", id);
    commit("dialogs/setFlowTypes", true, { root: true });
    dispatch("loading/closeDialog", null, { root: true });
  },
  async populatePointsOrder({ state, commit }, id) {
    const flowType = await findOne(id);
    console.log(flowType.pointsOrder);
    const pointsOrderWithPoints = await findByIds(
      flowType.pointsOrder.map((pointOrder) => pointOrder.id)
    );
    flowType.pointsOrder = await Promise.all(
      pointsOrderWithPoints.map(async (pointOrder) => ({
        id: pointOrder.id,
        trackingPoint: await findOnePoint(pointOrder.trackingPointId),
        impactionPoint: await findOnePoint(pointOrder.impactionPointId),
      }))
    );
    commit("setSelected", flowType);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
