import db from "@/database";

export const findOne = (id) => {
  return db.type.get(id);
};

export const findAll = () => {
  return db.type.orderBy("order").toArray();
};

export const getBySlug = (slug) => db.type.get({ slug });
