const state = {
  sheetInstallApp: false,
  confirm: {
    dialog: false,
    message: "",
    title: "",
    resolve: Function,
    type: "yesOrNot",
  },
  comments: {
    dialog: false,
    resolve: Function,
  },
  pageTitle: "",
  drawer: false,
};

const mutations = {
  changeSheetInstallApp(state, value) {
    state.sheetInstallApp = value;
  },
  setConfirmDialog(state, value) {
    state.confirm.dialog = value;
  },
  setConfirmMessage(state, value) {
    state.confirm.message = value;
  },
  setConfirmTitle(state, value) {
    state.confirm.title = value;
  },
  setConfirmResolve(state, value) {
    state.confirm.resolve = value;
  },
  setConfirmType(state, value) {
    state.confirm.type = value;
  },
  setCommentsDialog(state, value) {
    state.comments.dialog = value;
  },
  clearCommentsObject(state) {
    state.comments = {
      dialog: false,
      resolve: Function,
    };
  },
  setCommentsResolve(state, value) {
    state.comments.resolve = value;
  },
  setPageTitle(state, value) {
    state.pageTitle = value;
  },
  setDrawer(state, value) {
    state.drawer = value;
  },
};

const actions = {
  async showSheetInstallApp({ commit, getters }) {
    if (getters["isIos"] && !getters["isInStandaloneMode"]) {
      commit("changeSheetInstallApp", true);
    }
  },
  openConfirm({ commit }, { title, message, type = "yesOrNot" }) {
    if (type !== "yesOrNot" && type !== "confirm") {
      throw new Error("Tipo de confirmação incompatível");
    }
    return new Promise((resolve, reject) => {
      commit("setConfirmMessage", message);
      commit("setConfirmTitle", title);
      commit("setConfirmResolve", resolve);
      commit("setConfirmType", type);
      commit("setConfirmDialog", true);
    });
  },
  closeConfirm({ state }, confirmed) {
    state.confirm.resolve(confirmed);
  },
  openComments({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setCommentsResolve", resolve);
      commit("setCommentsDialog", true);
    });
  },
  confirmCommentsAndCloseDialog(
    { state, commit },
    { therapistComments, patientComments }
  ) {
    state.comments.resolve({ therapistComments, patientComments });
    commit("clearCommentsObject");
  },
  closeCommentsDialog({ commit }) {
    commit("clearCommentsObject");
  },
};

const getters = {
  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  },
  isInStandaloneMode() {
    return "standalone" in window.navigator && window.navigator.standalone;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
