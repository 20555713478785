import db from "@/database";
import { TherapyStatusEnum } from "@/domains/therapy/shared/application/therapy-status.enum";

export const findNotSynced = () =>
  db.therapy
    .where("status")
    .equals(TherapyStatusEnum.NOT_SYNCED)
    .or("synced")
    .equals(0)
    .toArray();

export const createOrUpdate = (therapy) => db.therapy.put(therapy);

export const deleteOne = (localId) => db.therapy.delete(localId);

export const deleteAll = () => db.therapy.clear();
