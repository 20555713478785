import Vue from "vue";
import Router from "vue-router";
import menu from "@/router/menu";
import auth from "@/router/auth";
import user from "@/router/user";
import store from "@/store";
import i18n from "@/plugins/i18n";

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", redirect: { name: "auth.login" } },
    {
      path: "/auth",
      component: () => import("@/layouts/Auth"),
      children: [...auth],
      props: {
        default: true,
        title: "",
      },
    },
    {
      path: "/find-therapist",
      component: () => import("@/domains/find-therapist/FindTherapist"),
      props: {
        default: true,
        title: "",
      },
    },
    {
      path: "/find-therapist/:id",
      component: () =>
        import("@/domains/find-therapist/therapist-details/TherapistDetails"),
      props: {
        default: true,
        title: "",
      },
    },
    {
      path: "/app",
      component: () => import("@/layouts/App"),
      children: [...menu, ...user],
    },
    {
      path: "/app/subscriptions/plans",
      name: "subscriptions.plans",
      icon: "shop",
      visible: true,
      component: () =>
        import("@/domains/subscriptions/presentation/pages/Plans"),
      meta: {
        requiresAuth: true,
        text: "userMenu.subscriptions",
        id: "subscriptions",
      },
    },
    {
      path: "*", // or '/index.html'
      beforeEnter: (to, from, next) => {
        next("/");
      },
    },
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (store.getters["language/setted"]) {
    i18n.locale = store.getters["language/setted"];
  }
  store.commit("settings/setPageTitle", i18n.t(to.meta.text));

  const isLogged = store.getters["login/isLogged"];
  const roles = store.getters["login/roles"];

  if (
    !!isLogged &&
    !!roles &&
    to.meta.requiresAuth &&
    to.matched.some(
      (m) =>
        !m.meta.role || roles.includes(m.meta.role) || roles.includes("admin")
    )
  ) {
    next();
  } else if (!to.meta.requiresAuth) {
    next();
  } else {
    console.log(to);
    store.commit("login/setForwardToAfterLogin", {
      path: to.path,
      query: to.query,
    });
    store.dispatch("login/logout");
  }
});

export default router;
