import router from "@/router";
import { update } from "@/services/external/users-service";
import { update as updateUserOnboarding } from "@/services/external/onboarding-service";
import labelProducts from "@/utils/products";

const state = {
  token: "",
  additionalData: {},
  forwardToAfterLogin: {
    path: "",
    query: "",
  },
  activeRole: null,
  onboarding: null,
};

const mutations = {
  set(state, payload) {
    const {
      roles,
      userName,
      photo,
      token,
      subscriptionProductType,
      termsAndPrivacyAccepted,
      onboarding,
    } = payload;
    state.token = token;
    if (roles.length === 1) {
      state.activeRole = roles[0];
    } else {
      state.activeRole = null;
    }
    state.additionalData = {
      roles,
      userName,
      photo,
      subscriptionProductType,
      termsAndPrivacyAccepted,
    };
    state.onboarding = onboarding || null;
  },

  logout(state) {
    state.token = undefined;
    state.additionalData = undefined;
  },

  updatePhoto(state, url) {
    state.additionalData.photo = url;
  },

  setForwardToAfterLogin(state, { path, query }) {
    state.forwardToAfterLogin = { path, query };
  },
  acceptTerms(state) {
    state.additionalData.termsAndPrivacyAccepted = true;
  },
  changeActiveRole(state, role) {
    state.activeRole = role;
  },
  updateOnboarding(state, onboarding) {
    state.onboarding = onboarding;
  },
};

const actions = {
  async login(context, response) {
    context.commit("set", response);
    const { path, query } = context.state.forwardToAfterLogin;
    if (path) {
      router.push({
        path,
        query,
      });
      context.commit("setForwardToAfterLogin", {});
    } else {
      router.push({ name: "home" });
    }
  },

  async logout(context) {
    context.commit("logout");
    context.commit("sync/forceCloseDialog", null, { root: true });
    context.commit("dialogs/clearState", null, { root: true });
    await router.push({ name: "auth.login" });
  },

  async update({ commit }, user) {
    try {
      let response = await update(user);
      commit("set", response.data);
    } catch (error) {
      throw error;
    }
  },

  async updateOnboarding({ commit }, onboarding) {
    try {
      await updateUserOnboarding(onboarding);
      commit("updateOnboarding", onboarding);
    } catch (error) {
      throw error;
    }
  },

  acceptTerms({ commit }) {
    commit("acceptTerms");
  },

  changeActiveRole({ commit }, role) {
    commit("changeActiveRole", role);
  },
};

const getters = {
  isLogged(state) {
    return !!state.token;
  },
  roles(state) {
    return state.additionalData?.roles || null;
  },
  userFirstName(state) {
    return state.additionalData.userName.split(" ")[0];
  },
  photo(state) {
    return state.additionalData.photo;
  },
  userName(state) {
    return state.additionalData.userName;
  },
  token(state) {
    return state.token || "";
  },
  isTherapist(state) {
    return state.additionalData.roles.some((role) => role === "therapist");
  },
  subscriptionProductType(state) {
    return state.additionalData.subscriptionProductType;
  },
  subscriptionProductTypeLabel(state) {
    return labelProducts[state.additionalData.subscriptionProductType] || "";
  },
  termsAndPrivacyAccepted(state) {
    return state.additionalData.termsAndPrivacyAccepted;
  },
  activeRole(state) {
    return state.activeRole;
  },
  onboarding(state) {
    return state.onboarding;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
