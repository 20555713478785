import ProductTypes from "@/utils/product-types";

export default [
  {
    path: "/home",
    name: "home",
    icon: "home",
    visible: true,
    component: () => import("@/domains/dashboard/presentation/pages/Dashboard"),
    meta: {
      requiresAuth: true,
      text: "menu.home",
      id: "home",
    },
  },

  {
    path: "/therapys",
    name: "therapys",
    icon: "healing",
    visible: true,
    component: () =>
      import("@/domains/therapy/shared/presentation/pages/TherapysList"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.therapys",
      id: "therapys",
    },
  },
  {
    path: "/therapys/stepper",
    name: "therapys.stepper",
    icon: "healing",
    visible: false,
    component: () =>
      import("@/domains/therapy/shared/presentation/pages/TherapyStepper"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.therapys",
      productTypes: [
        ProductTypes.ADVANCED,
        ProductTypes.PREMIUM,
        ProductTypes.BASIC,
      ],
    },
  },
  {
    path: "/my-therapys",
    name: "myTherapys",
    icon: "format_list_bulleted",
    visible: true,
    component: () =>
      import("@/domains/therapy/review/patient/presentation/pages/MyTherapys"),
    meta: {
      requiresAuth: true,
      role: "patient",
      text: "menu.myTherapys",
      id: "myTherapys",
    },
  },
  {
    path: "/sync",
    name: "sync",
    icon: "sync",
    visible: false,
    component: () => import("@/domains/sync/presentation/pages/Sync"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.sync",
      id: "sync",
    },
  },
  {
    path: "/therapy/review",
    name: "therapistReview",
    icon: "format_list_bulleted",
    visible: false,
    component: () =>
      import("@/domains/therapy/review/therapist/presentation/pages/Review"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.therapyReview",
    },
  },
  {
    path: "/patient/review",
    name: "userReview",
    icon: "format_list_bulleted",
    visible: false,
    component: () =>
      import("@/domains/therapy/review/patient/presentation/pages/Review"),
    meta: {
      requiresAuth: true,
      role: "patient",
      text: "menu.userReview",
    },
  },
  {
    path: "/appointments-therapist",
    name: "listAppointmentsTherapist",
    icon: "event",
    visible: true,
    component: () =>
      import(
        "@/domains/appointments/therapist/presentation/pages/AppointmentsDashboard"
      ),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.appointmentsTherapist",
      productTypes: [ProductTypes.ADVANCED, ProductTypes.PREMIUM],
      id: "appointmentsTherapist",
    },
  },
  {
    path: "/appointments-patient",
    name: "listAppointmentsPatient",
    icon: "event_note",
    visible: true,
    component: () =>
      import("@/domains/appointments/patient/presentation/pages/List"),
    meta: {
      requiresAuth: true,
      role: "patient",
      text: "menu.appointmentsPatient",
      id: "appointmentsPatient",
    },
  },
  {
    path: "/appointments-patient/new",
    name: "newAppointmentPatient",
    icon: "event_note",
    visible: false,
    component: () =>
      import(
        "@/domains/appointments/patient/presentation/pages/StepperNewAppointment"
      ),
    meta: {
      requiresAuth: true,
      role: "patient",
    },
  },
  {
    path: "/tutoriais",
    name: "tutorial",
    icon: "mdi-school",
    visible: true,
    component: () =>
      import("@/domains/user/shared/presentation/pages/Tutorial"),
    meta: {
      requiresAuth: true,
      text: "menu.tutorial",
      id: "tutorial",
    },
  },
  {
    path: "/settings",
    name: "settings",
    icon: "settings",
    visible: true,
    component: () =>
      import("@/domains/user/therapist/presentation/pages/Settings"),
    meta: {
      requiresAuth: true,
      role: "therapist",
      text: "menu.settings",
      id: "settings",
    },
  },
];
