export default [
  {
    path: "/login",
    name: "auth.login",
    visible: false,
    component: () => import("@/domains/auth/presentation/pages/Login"),
    meta: {
      requiresAuth: false,
      text: "Login",
    },
    props: {
      title: "Bem vindo",
    },
  },
  {
    path: "/register",
    name: "auth.register",
    visible: false,
    component: () => import("@/domains/auth/presentation/pages/Register"),
    meta: {
      requiresAuth: false,
      text: "Registro",
    },
    props: {
      title: "Registro de novo usuário",
    },
  },
  {
    path: "/reset-password",
    name: "auth.resetPassword",
    visible: false,
    component: () => import("@/domains/auth/presentation/pages/ResetPassword"),
    meta: {
      requiresAuth: false,
      text: "Criar nova senha",
    },
    props: {
      title: "Criar nova senha",
    },
  },
  {
    path: "/auth/confirm",
    name: "auth.confirmRegistry",
    visible: false,
    component: () =>
      import("@/domains/auth/presentation/pages/ConfirmRegistry"),
    meta: {
      requiresAuth: false,
      text: "Confirmação de registro",
    },
    props: {
      title: "Confirmação de registro",
    },
  },
];
