import { findByTrackingPoint } from "@/services/local/points-order-service";

import {
  findByIds,
  findOne as findOnePoint,
} from "@/services/local/points-service";
import { impactionPointsDialogMapper } from "@/domains/therapy/steps/execute/domains/points/mappers/impaction-points-dialog.mapper";

const state = {
  sequence: [],
  impactionPoints: [],
  selectedTrackingPoint: {},
  selectedTypeId: 0,
};

const mutations = {
  setSequence(state, sequence) {
    state.sequence = sequence;
  },
  changePointsOrder(state, newPoints) {
    state.pointsOrder = newPoints;
  },
  setImpactionPoints(state, impactionPoints) {
    state.impactionPoints = impactionPoints;
  },
  setSelectedTrackingPoint(state, trackingPoint) {
    state.impactionPoints = [];
    state.selectedTrackingPoint = trackingPoint;
  },
  setSelectedTypeId(state, value) {
    state.selectedTypeId = value;
  },
  replaceItemImpactionPoints(state, { index, newItem }) {
    state.impactionPoints.splice(index, 1, newItem);
  },
};

const actions = {
  async loadImpactionPointsWithPar({ commit, state, dispatch }) {
    const pointsOrderOfTrackingPoint = await findByTrackingPoint(
      state.selectedTrackingPoint.id
    );

    const filteredPointsOrder = pointsOrderOfTrackingPoint.filter(
      (pointOrder) => pointOrder.par.typeId === state.selectedTypeId
    );

    const impactionPointsIds = filteredPointsOrder.map(
      (po) => po.impactionPointId
    );

    const impactionPoints = await findByIds(impactionPointsIds);

    const populatedImpactionPoints = impactionPoints.map(
      async (impactionPoint) => {
        const pointOrder = filteredPointsOrder.find(
          (pointOrder) => pointOrder.impactionPointId === impactionPoint.id
        );

        return impactionPointsDialogMapper(pointOrder, impactionPoint);
      }
    );
    const finalPoints = await Promise.all(populatedImpactionPoints);
    if (finalPoints.length === 1) {
      const pointOrderId = finalPoints[0].pointOrderId;
      dispatch("pares/selectPar", pointOrderId, { root: true });
    } else {
      commit("dialogs/setParesImpactionPoints", true, { root: true });
    }

    finalPoints.sort((a, b) => a.order - b.order);
    commit("setImpactionPoints", finalPoints);
  },
  changeFieldAddedToDiagnosis({ state, commit }, { parId, isAdded }) {
    const index = state.impactionPoints.findIndex(
      (impactionPoint) => impactionPoint.parId === parId
    );
    if (index >= 0) {
      commit("replaceItemImpactionPoints", {
        index,
        newItem: {
          ...state.impactionPoints[index],
          isAddedInDiagnosis: isAdded,
        },
      });
    }
  },
};

const getters = {
  sequence(state) {
    return state.sequence;
  },
  impactionPoints(state) {
    return state.impactionPoints;
  },
  selectedTrackingPoint(state) {
    return state.selectedTrackingPoint;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
