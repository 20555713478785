<template>
  <v-card class="rounded-10 mt-3" outlined>
    <v-card-title class="secondary--text title pb-0">
      {{ title }}
    </v-card-title>
    <v-card-text class="py-2">
      <slot name="default" />
    </v-card-text>
    <v-card-actions
      v-if="$slots.actions"
      class="d-flex justify-center text-center py-5"
    >
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "TherapyCard",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
