import { addNamespace } from "@/store/utils";

const SEQUENCE_MODULE = "sequence";

export const _ACTIONS = {
  POPULATE_PROPERTIES_WITH_ITEMS: "POPULATE_PROPERTIES_WITH_ITEMS",
  TOGGLE_ITEM_FROM_PROPERTY_TO_SEQUENCE:
    "TOGGLE_ITEM_FROM_PROPERTY_TO_SEQUENCE",
  REMOVE_SELECTED_ITEM: "REMOVE_SELECTED_ITEM",
  CLEAR_SELECTED_ITEMS: "CLEAR_SELECTED_ITEMS",
  MOUNT_SEQUENCE: "MOUNT_SEQUENCE",
  STEPS_TYPE: "STEPS_TYPE",
  STEPS_OTHER_PROPERTIES: "STEPS_OTHER_PROPERTIES",
  OPEN_DETAIL_DIALOG: "OPEN_DETAIL_DIALOG",
};

export const _MUTATIONS = {
  SET_SEQUENCE: "SET_SEQUENCE",
  SET_ITEMS_OF_SELECTED_PROPERTY: "SET_ITEMS_OF_SELECTED_PROPERTY",
  CLEAR_SEQUENCE: "CLEAR_SEQUENCE",
  SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
  ADD_SELECTED_ITEM: "ADD_SELECTED_ITEM",
  REMOVE_SELECTED_ITEM: "REMOVE_SELECTED_ITEM",
  CHANGE_TUMORAL_PHENOMENA_DIALOG: "CHANGE_TUMORAL_PHENOMENA_DIALOG",
  CHANGE_IMPACTION_POINTS_DIALOG: "CHANGE_IMPACTION_POINTS_DIALOG",
  SET_INDEX_SELECTED_PROPERTY: "SET_INDEX_SELECTED_PROPERTY",
};

export const SEQUENCE = {
  ACTIONS: addNamespace(SEQUENCE_MODULE, _ACTIONS),
  MUTATIONS: addNamespace(SEQUENCE_MODULE, _MUTATIONS),
};
