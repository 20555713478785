<template>
  <div>
    <v-edit-dialog
      large
      save-text="Salvar"
      cancel-text="Cancelar"
      @save="emitSave"
      @open="$emit('open')"
    >
      <span class="body-2 grey--text text--darken-3">
        <strong>{{ label }}: </strong>
        {{ newValue }}
        <v-icon v-if="editable">edit</v-icon>
      </span>
      <template v-if="editable" v-slot:input>
        <div class="mt-3">
          <v-textarea v-model="newValue" :label="label"></v-textarea>
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    returnValue: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      newValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
  methods: {
    emitSave() {
      this.$emit("save", { [this.returnValue]: this.newValue });
    },
  },
};
</script>

<style></style>
