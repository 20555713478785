import patient from "../patient/store/patient-module";
import therapist from "../therapist/store/therapist-module";

export default {
  namespaced: true,
  modules: {
    patient,
    therapist,
  },
};
