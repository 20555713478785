<template>
  <v-text-field
    v-mask="'##/##/####'"
    :value="dateFormatted"
    v-bind="$props"
    validate-on-blur
    :outlined="outlined"
    @blur="emitInput"
  />
</template>

<script>
//import VTextField from "vuetify/es5/presentation/VTextField/VTextField";
import moment from "moment";
export default {
  props: {
    /* ...VTextField.options.props */
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateFormatted: "",
  }),
  watch: {
    value(value) {
      this.formatDate(value);
    },
  },
  created() {
    this.formatDate(this.value);
  },
  methods: {
    emitInput(event) {
      const value = event.target.value;
      if (value) {
        const date = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
        this.$emit("input", date);
      }
    },
    formatDate(value) {
      if (value) {
        this.dateFormatted = moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        this.dateFormatted = "";
      }
    },
  },
};
</script>

<style></style>
