<template>
  <v-row justify="space-between" class="px-3">
    <v-btn
      color="secondary"
      rounded
      elevation="0"
      large
      :disabled="disabledBack"
      outlined
      @click="$emit('click-back')"
    >
      <v-icon class="ml-1">arrow_back</v-icon>
      Voltar
    </v-btn>
    <v-btn
      rounded
      elevation="0"
      large
      color="secondary"
      :disabled="disabledNext"
      @click="$emit('click-next')"
    >
      <template v-if="isLastStep">
        <v-icon class="mr-1">check</v-icon> Finalizar
      </template>
      <template v-else>
        Próximo <v-icon class="ml-1">arrow_forward</v-icon>
      </template>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "StepButtonsTherapy",
  props: {
    disabledNext: {
      type: Boolean,
      default: false,
    },
    disabledBack: {
      type: Boolean,
      default: false,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
