const initialState = () => ({
  pares: {
    impactionPoints: false,
    checkPar: false,
    par: false,
  },
  checkCategories: {
    trackingPoints: false,
    impactionPoints: false,
  },
  resolutionZones: false,
  flowTypes: false,
  oligoelements: {
    types: false,
    specific: false,
  },
  chakras: false,
  chromosomes: false,
  psycoemotionals: false,
  poisoning: false,
  hormonal: false,
  spiritual: false,
  complementaryTherapy: false,
  sophrological: false,
  specialPairs: false,
  herpesCheck: false,
  fsmiProtocol: false,
  basicProtocol: false,
  lymphaticProtocol: false,
  movement3D12: false,
});

const state = initialState();

const mutations = {
  clearState(state) {
    Object.assign(state, initialState());
  },
  setParesCheckPar(state, value) {
    state.pares.checkPar = value;
  },
  setParDialog(state, value) {
    state.pares.par = value;
  },
  setParesImpactionPoints(state, value) {
    state.pares.impactionPoints = value;
  },
  setCheckCategoriesTrackingPoints(state, value) {
    state.checkCategories.trackingPoints = value;
  },
  setCheckCategoriesImpactionPoints(state, value) {
    state.checkCategories.impactionPoints = value;
  },
  setResolutionZones(state, value) {
    state.resolutionZones = value;
  },
  setFlowTypes(state, value) {
    state.flowTypes = value;
  },
  setOligoelementsTypes(state, value) {
    state.oligoelements.types = value;
  },
  setOligoelementsSpecific(state, value) {
    state.oligoelements.specific = value;
  },
  setChakras(state, value) {
    state.chakras = value;
  },
  setChromosomes(state, value) {
    state.chromosomes = value;
  },
  setPsycoemotionals(state, value) {
    state.psycoemotionals = value;
  },
  setPoisoning(state, value) {
    state.poisoning = value;
  },
  setHormonal(state, value) {
    state.hormonal = value;
  },
  setSpiritual(state, value) {
    state.spiritual = value;
  },
  setComplementaryTherapy(state, value) {
    state.complementaryTherapy = value;
  },
  setSophrological(state, value) {
    state.sophrological = value;
  },
  setSpecialPairs(state, value) {
    state.specialPairs = value;
  },
  setHerpesCheck(state, value) {
    state.herpesCheck = value;
  },
  setFsmiProtocol(state, value) {
    state.fsmiProtocol = value;
  },
  setBasicProtocol(state, value) {
    state.basicProtocol = value;
  },
  setLymphaticProtocol(state, value) {
    state.lymphaticProtocol = value;
  },
  setMovement3D12(state, value) {
    state.movement3D12 = value;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
