import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  key: "vuex-par-persist",
  modules: [
    "chakras",
    "checkCategories",
    "chromosomes",
    "diagnosis",
    "dialogs",
    "flowTypes",
    "hormonal",
    "language",
    "login",
    "oligoelements",
    "pares",
    "points",
    "poisoning",
    "potentialSpaces",
    "psycoemotionals",
    "resolutionZones",
    "sequence",
    "spiritual",
    "subscriptions",
    "therapy",
    "tumoralPhenomena",
    "vascularPhenomena",
  ],
});

export default vuexLocal;
