<template>
  <div class="d-flex align-center mt-3">
    <v-icon class="mr-2">{{ icon }}</v-icon>
    <span :class="`mr-1 ${bold ? 'font-weight-bold' : ''}`"> {{ label }}:</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
