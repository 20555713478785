<template>
  <div v-if="items && items.length > 0">
    <v-edit-dialog
      persistent
      large
      save-text="Salvar"
      cancel-text="Cancelar"
      @save="emitSave"
      @open="$emit('open')"
      @close="close"
      @cancel="close"
    >
      <span class="body-2 grey--text text--darken-3">
        <strong>{{ label }}: </strong>
        {{ handleProperties(newValue) }}
        <v-icon v-if="editable">edit</v-icon>
      </span>
      <template v-if="editable" v-slot:input>
        <div class="mt-3">
          <p class="font-weight-medium">{{ label }}</p>
          <v-list v-if="hasItems">
            <v-list-item-group v-model="newValue" multiple color="primary">
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :value="item"
                class="list-item elevation-2"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-icon>
                      {{ active ? "check_circle" : "check_circle_outline" }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item[fieldName]" />
                  </v-list-item-content>
                </template>
                <v-divider></v-divider>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div v-else class="font-italic">Nenhum item para selecionar.</div>
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    returnValue: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: "name",
    },
  },
  data: function () {
    return {
      newValue: this.value,
    };
  },
  computed: {
    hasItems() {
      return this.items && this.items.length > 0;
    },
    hasRules() {
      return this.rules && this.rules.length > 0;
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
  methods: {
    emitSave() {
      if (this.validate()) {
        this.$emit("save", { [this.returnValue]: this.newValue });
      } else {
        this.newValue = this.value;
      }
    },
    validate() {
      if (!this.hasRules) return true;
      const results = this.rules.map((rule) => rule(this.newValue));
      const hasError = results.some((result) => typeof result === "string");
      if (hasError) {
        const errors = results.filter((result) => typeof result === "string");
        this.$toasted.global.error({ message: errors[0] });
        return false;
      }
      return true;
    },
    handleProperties(array) {
      if (array && array.length > 0) {
        return array
          .map((e) => e[this.fieldName])
          .join(", ")
          .concat(".");
      } else {
        return "Nenhum(a).";
      }
    },
    close() {
      this.newValue = this.value;
    },
  },
};
</script>

<style></style>
