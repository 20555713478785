<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :max-width="options.width"
    persistent
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4 body-1 pre-line">
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0 d-flex justify-center">
        <div v-show="isYesOrNotType">
          <div>
            <v-btn color="grey" class="mr-2" outlined @click.native="cancel">
              Não
            </v-btn>
            <v-btn color="primary darken-1" outlined @click.native="agree">
              Sim
            </v-btn>
          </div>
        </div>
        <div v-show="!isYesOrNotType">
          <v-btn color="primary darken-1" outlined @click.native="agree">
            Confirmar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    options: {
      color: "primary",
      width: 600,
    },
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.settings.confirm.dialog;
      },
      set(value) {
        this.$store.commit("settings/setConfirmDialog", value);
      },
    },
    title() {
      return this.$store.state.settings.confirm.title;
    },
    message() {
      return this.$store.state.settings.confirm.message;
    },
    isYesOrNotType() {
      return this.$store.state.settings.confirm.type === "yesOrNot";
    },
  },
  methods: {
    agree() {
      this.$store.dispatch("settings/closeConfirm", true);
      this.dialog = false;
    },
    cancel() {
      this.$store.dispatch("settings/closeConfirm", false);
      this.dialog = false;
    },
  },
};
</script>
