<template>
  <div class="grey lighten-4 d-flex justify-center mb-5 py-3">
    <v-img :src="image" max-width="150" contain />
  </div>
</template>

<script>
export default {
  name: "ImageSection",
  props: {
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
