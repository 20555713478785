<template>
  <div class="px-5 py-3 orange accent-2 mb-10 d-flex align-center rounded-lg">
    <v-icon color="black" class="mr-3">warning</v-icon>
    <slot />
  </div>
</template>

<script>
export default {
  name: "WarningSection",
};
</script>

<style scoped></style>
