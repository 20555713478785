import Vue from "vue";
import VeeValidate from "vee-validate";
import VeeDictionaryBr from "vee-validate/dist/locale/pt_BR";
import "@/misc/custom-messages-validator";

Vue.use(VeeValidate, {
  locale: "pt_BR",
  dictionary: {
    pt_BR: VeeDictionaryBr,
  },
});
