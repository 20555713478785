import Vue from "vue";

const errorHandler = (error, sweetAlert = false) => {
  console.error(error);
  let message = "Aconteceu algo inesperado. Tente novamente";
  if (error?.response?.status >= 400) {
    message = error?.response?.data?.message || message;
  }
  if (sweetAlert) {
    Vue.prototype.$errorAlert({ message });
  } else {
    Vue.toasted.global.error({ message });
  }
};

Vue.prototype.$errorHandler = errorHandler;
