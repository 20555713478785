import { transformCategoriesToString } from "@/utils/categories";

export const impactionPointsDialogMapper = ({
  pointOrder,
  impactionPoint,
}) => ({
  name: impactionPoint.name,
  pointOrderId: pointOrder.id,
  categories: transformCategoriesToString(pointOrder.par.categories),
});
