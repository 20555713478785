<template>
  <div>
    <h2 class="subtitle-1">{{ text }}</h2>
    <v-divider class="mb-3" />
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
