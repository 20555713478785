import { findByIds as findByIdsCategories } from "@/services/local/categories-service";
import {
  findByIds as findByIdsPointsOrder,
  findByPar,
  findByTrackingPoint,
} from "@/services/local/points-order-service";
import { findByIds, findOne } from "@/services/local/points-service";
import { getBySlug } from "@/services/local/types-service";
import { PAR_TYPE_RASTREIO_SLUG } from "@/utils/constants";
import { impactionPointsDialogMapper } from "../mappers/impaction-points-dialog.mapper";
import { transformCategoriesToString } from "@/utils/categories";

const state = {
  selectedCategoriesIds: new Set(),
  selectedTrackingPoint: {},
  selectedCategory: {},
  selectedImpactionPoint: {},
  categoriesWithTrackingPoints: [],
  impactionPoints: [],
  diagnosePar: {},
  selectedPar: {
    type: {},
  },
  paresAlreadyAddedInDiagnose: [],
};

const mutations = {
  setCategoriesIds(state, ids) {
    state.selectedCategoriesIds = new Set(ids);
  },
  setCategoriesWithTrackingPoints(state, categoriesWithTrackingPoints) {
    state.categoriesWithTrackingPoints = categoriesWithTrackingPoints;
  },
  setParesAlreadyAddedInDiagnose(state, paresAlreadyAddedInDiagnose) {
    state.paresAlreadyAddedInDiagnose = paresAlreadyAddedInDiagnose;
  },
  setImpactionPoints(state, value) {
    state.impactionPoints = value;
  },
  setSelectedTrackingPoint(state, value) {
    state.selectedTrackingPoint = value;
  },
  setSelectedCategory(state, value) {
    state.selectedCategory = value;
  },
  setSelectedImpactionPoint(state, value) {
    state.selectedImpactionPoint = value;
  },
  setSelectedPar(state, value) {
    state.selectedPar = value;
  },
};

const actions = {
  startByIds({ commit, dispatch }, ids) {
    commit("setCategoriesIds", ids);
    dispatch("findStepsToCheck");
  },

  async findStepsToCheck({ commit, dispatch }) {
    await dispatch("findParesAlreadyAddedToDiagnoseByCategory");
    await dispatch("findParesNotAddedInDiagnoseByCategory");

    commit("dialogs/setCheckCategoriesTrackingPoints", true, {
      root: true,
    });
  },

  findParesAlreadyAddedToDiagnoseByCategory({ rootState, commit }) {
    const paresWithCategoriesAlreadyAddedToDiagnose =
      rootState.diagnosis.diagnose.pares
        .map((diagnosePar) => diagnosePar.par)
        .filter((par) =>
          par.categories.some((parCategory) =>
            state.selectedCategoriesIds.has(parCategory.id)
          )
        )
        .map((par) => ({
          id: par.id,
          name: par.name,
          categories: transformCategoriesToString(
            par.categories.filter((parCategory) =>
              state.selectedCategoriesIds.has(parCategory.id)
            )
          ),
        }));
    commit(
      "setParesAlreadyAddedInDiagnose",
      paresWithCategoriesAlreadyAddedToDiagnose
    );
  },

  async findParesNotAddedInDiagnoseByCategory({ commit, state }) {
    const categories = await findByIdsCategories([
      ...state.selectedCategoriesIds,
    ]);

    const categoriesWithParesNotInDiagnosePromise = categories
      .filter((category) => category.pares.length > 0)
      .map(async (category) => {
        const paresIds = category.pares.map((par) => par.id);

        const pointsOrder = await findByIdsPointsOrder(paresIds);

        const trackingPointsIds = pointsOrder.map(
          (pointOrder) => pointOrder.trackingPointId
        );
        const uniqueTrackingPoints = new Set([...trackingPointsIds]);
        const uniqueTrackingPointsPopulated = await findByIds([
          ...uniqueTrackingPoints,
        ]);
        const trackingPointWithOnlyImportantFields =
          uniqueTrackingPointsPopulated.map((trackingPoint) => ({
            id: trackingPoint.id,
            name: trackingPoint.name,
          }));

        return {
          categoryId: category.id,
          categoryName: category.name,
          trackingPoints: trackingPointWithOnlyImportantFields,
        };
      });

    const categoriesWithParesNotInDiagnose = await Promise.all(
      categoriesWithParesNotInDiagnosePromise
    );
    commit("setCategoriesWithTrackingPoints", categoriesWithParesNotInDiagnose);
  },

  async findImpactionPointsByTrackingPoint({ state, commit }) {
    const pointsOrderResult = await findByTrackingPoint(
      state.selectedTrackingPoint.id
    );

    const { id: typeId } = await getBySlug(PAR_TYPE_RASTREIO_SLUG);

    const impactionPoints = pointsOrderResult
      .filter(
        (po) =>
          po.par.typeId === typeId &&
          po.par.categories.some(
            (category) => category.id === state.selectedCategory.categoryId
          )
      )
      .map(async (po) => {
        const impactionPoint = await findOne(po.impactionPointId);
        return impactionPointsDialogMapper({ pointOrder: po, impactionPoint });
      });

    commit("setImpactionPoints", await Promise.all(impactionPoints));
    commit("dialogs/setCheckCategoriesImpactionPoints", true, {
      root: true,
    });
  },
  async findParAndOpenParDialog({ state, dispatch, commit }) {
    await dispatch(
      "pares/selectPar",
      state.selectedImpactionPoint.pointOrderId,
      { root: true }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
