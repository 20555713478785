import dayjs from "dayjs";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormatPlugin);

export const transformDatesStartAndEndToCalendarFormat = ({
  startAt,
  endAt,
  ...rest
}) => {
  return {
    startAt: dayjs(startAt).format("YYYY-MM-DD HH:mm"),
    endAt: dayjs(endAt).format("YYYY-MM-DD HH:mm"),
    ...rest,
  };
};
