<template>
  <!-- <v-btn fab right @click="$vuetify.goTo(target, options)">
    <v-icon>add</v-icon>
  </v-btn> -->
  <div></div>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: "",
    },
  },
  computed: {
    options() {
      return {
        el: this.componentId,
        duration: 1000,
        easing: "linear",
      };
    },
  },
  methods: {
    roll() {
      window.setInterval(() => {
        // If at page bottom, scroll by to top
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight)
          window.scrollTo(0, 0);
        // Scroll 1px down
        else window.scrollBy(0, 1);
      }, 25);
    },
  },
};
</script>

<style></style>
