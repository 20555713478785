<template>
  <v-card>
    <v-card-title>
      <v-btn icon color="grey" class="mr-3" large @click="$emit('close')">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      {{ title }}
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <slot name="default" />
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
