import Toasted from "vue-toasted";
import Vue from "vue";

Vue.use(Toasted, {
  position: "bottom-center",
  duration: 5000,
  className: "toasted",
});

const successConfig = {
  type: "success",
  icon: "check",
};

const errorConfig = {
  type: "error",
  icon: "error",
};

const infoConfig = {
  type: "info",
  icon: "info",
};

Vue.toasted.register("defaultError", "Erro, tente novamente!", errorConfig);

Vue.toasted.register(
  "defaultSuccess",
  "Ação realizada com sucesso!",
  successConfig
);

Vue.toasted.register(
  "error",
  (payload) => {
    return payload && payload.message ? payload.message : "";
  },
  errorConfig
);

Vue.toasted.register(
  "success",
  (payload) => {
    return payload && payload.message ? payload.message : "";
  },
  successConfig
);

Vue.toasted.register(
  "info",
  (payload) => {
    return payload && payload.message ? payload.message : "";
  },
  infoConfig
);
