/**
 * @file driver.js自定义配置
 *
 */

export default {
  className: "onboarding", // driver popover的类名，避免css作用域污染
  allowClose: false, // 点击遮罩是否关闭popover
  // opacity: 0, // 背景透明度，0 表示只有popover没有遮罩
  padding: 0, // 元素与边缘的距离
  stageBackground: "rgba(255, 255, 255, 0.2)", // Semi-transparent white
  prevBtnText: "Anterior", // 上一步按钮文字
  nextBtnText: "Próximo", // 下一步按钮文字
  closeBtnText: "Pular tour", // 关闭按钮文字
  doneBtnText: "Finalizar", // 完成按钮文字
  // showButtons: false
};
