import db from "@/database";

export const findOne = (id) => db.pointsOrder.get(id);

export const findByIds = (ids) =>
  db.pointsOrder.where("id").anyOf(ids).toArray();

export const findByTrackingPoint = (trackingPoint) =>
  db.pointsOrder.where("trackingPointId").anyOf(trackingPoint).sortBy("order");

export const findById = (id) => db.pointsOrder.get(id);

export const findByPar = (parId) => db.pointsOrder.get({ parId });
