<template>
  <div class="d-flex flex-column align-center pa-5 text-center black--text">
    <span>{{ label }}:</span>
    <image-expansion
      v-if="image"
      :src="image"
      :title="name"
      :description="imageDescription"
      :expand-image="expandImage"
    />
    <div v-if="showSides">
      <v-checkbox
        v-model="sidesArray"
        hide-details
        label="Direito"
        value="R"
        @change="emitInput"
      />
      <v-checkbox
        v-model="sidesArray"
        hide-details
        label="Esquerdo"
        value="L"
        @change="emitInput"
      />
    </div>
  </div>
</template>

<script>
import { transformSidesArrayToString } from "@/utils/points";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    imageDescription: {
      type: String,
      default: "",
    },
    expandImage: {
      type: Boolean,
      default: true,
    },
    showSides: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    sidesArray: [],
  }),
  methods: {
    emitInput() {
      const reversedArray = transformSidesArrayToString(this.sidesArray);
      this.$emit("input", reversedArray?.join("") || null);
    },
  },
};
</script>

<style></style>
