import Vue from "vue";
import Vuex from "vuex";

import loading from "@/store/loading-module";
import login from "@/store/login-module";
import language from "@/store/language-module";
import therapy from "@/domains/therapy/shared/store/therapy-module";
import points from "@/domains/therapy/steps/execute/domains/points/store/points-module";
import pares from "@/domains/therapy/steps/execute/domains/pares/store/pares-module";
import diagnosis from "@/domains/therapy/steps/diagnose/store/diagnosis-module";
import sync from "@/domains/sync/store/sync-module";
import tumoralPhenomena from "@/store/tumoral-phenomena-module";
import sequence from "@/domains/therapy/steps/mount-sequence/store/sequence";
import settings from "@/store/settings-module";
import dialogs from "@/domains/therapy/shared/store/dialogs-module";
import vascularPhenomena from "@/store/vascular-phenomena-module";
import resolutionZones from "@/domains/therapy/steps/execute/domains/resolution-zones/store/resolution-zones-module";
import potentialSpaces from "@/store/potential-spaces-module";
import checkCategories from "@/domains/therapy/steps/execute/domains/check-categories/store/check-categories-module";
import flowTypes from "@/domains/therapy/steps/execute/domains/flow-types/store/flow-types-module";
import oligoelements from "@/domains/therapy/steps/execute/domains/oligoelements/store/oligoelements-module";
import chakras from "@/domains/therapy/steps/execute/domains/chakras/store/chakras-module";
import chromosomes from "@/domains/therapy/steps/execute/domains/chromosomes/store/chromosomes-module";
import psycoemotionals from "@/domains/therapy/steps/execute/domains/psycoemotionals/store/psycoemotionals-module";
import poisoning from "@/domains/therapy/steps/execute/domains/poisoning/store/poisoning-module";
import hormonal from "@/domains/therapy/steps/execute/domains/hormonal/store/hormonal-module";
import spiritual from "@/domains/therapy/steps/execute/domains/spiritual/store/spiritual-module";
import appointments from "@/domains/appointments/store";
import subscriptions from "@/domains/subscriptions/store/subscriptions-module";
import autoScroll from "@/domains/therapy/steps/execute/store/auto-scroll-module";
import complementaryTherapies from "@/domains/therapy/steps/execute/domains/complementary-therapies/store/complementary-therapies-module";
import sophrological from "@/domains/therapy/steps/execute/domains/sophrological/store/sophrological-module";
import movement3D12 from "@/domains/therapy/steps/execute/domains/3d-movement-1-2/store/3d-movement-1-2-module";
import basicProtocol from "@/domains/therapy/steps/execute/domains/basic-protocol/store/basic-protocol-module";
import fsmiProtocol from "@/domains/therapy/steps/execute/domains/fsmi-protocol/store/fsmi-protocol-module";
import herpesCheck from "@/domains/therapy/steps/execute/domains/herpes-check/store/herpes-check-module";
import lymphaticProtocol from "@/domains/therapy/steps/execute/domains/lymphatic-protocol/store/lymphatic-protocol-module";
import specialPairs from "@/domains/therapy/steps/execute/domains/special-pairs/store/special-pairs-module";
import vuexLocal from "@/plugins/vuex-persist";
import onboarding from "./onboarding-module";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const config = {
  modules: {
    loading,
    login,
    language,
    therapy,
    points,
    pares,
    diagnosis,
    sync,
    tumoralPhenomena,
    sequence,
    settings,
    dialogs,
    vascularPhenomena,
    resolutionZones,
    potentialSpaces,
    checkCategories,
    flowTypes,
    oligoelements,
    chakras,
    chromosomes,
    psycoemotionals,
    poisoning,
    hormonal,
    spiritual,
    appointments,
    subscriptions,
    autoScroll,
    complementaryTherapies,
    sophrological,
    movement3D12,
    basicProtocol,
    fsmiProtocol,
    herpesCheck,
    lymphaticProtocol,
    specialPairs,
    onboarding,
  },
  strict: debug,
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store(config);
