<template>
  <v-row :justify="justify" class="lighten-3 ma-0 flex-wrap">
    <v-col md="6" cols="12" class="d-flex flex-column align-center">
      <view-point
        label="Ponto de Rastreio"
        :name="trackingPoint.name"
        :image="trackingPoint.image"
        :image-description="trackingPoint.imageDescription"
        :expand-image="expandImages"
      />
      <slot name="trackingPointContainer" />
    </v-col>
    <v-col md="6" cols="12" class="d-flex flex-column align-center">
      <view-point
        label="Ponto de Impactação"
        :name="impactionPoint.name"
        :image="impactionPoint.image"
        :image-description="impactionPoint.imageDescription"
        :expand-image="expandImages"
      />
      <slot name="impactionPointContainer" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    impactionPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
      }),
    },
    trackingPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
      }),
    },
    justify: {
      type: String,
      default: "center",
    },
    expandImages: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
@media print {
  .custom-bg {
    background-color: red !important;
  }
}
</style>
