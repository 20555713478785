import api from "./api-service";

const baseUrl = "appointments";

export const createAppointment = ({ startAt, endAt, isPublic }) =>
  api().post(baseUrl, { startAt, endAt, isPublic });

export const getAppointmentsByTherapist = () =>
  api().get(baseUrl + "/therapist");

export const getAppointmentDetailsById = (id) => api().get(`${baseUrl}/${id}`);

export const updateAppointment = ({ id, startAt, endAt, isPublic }) =>
  api().put(baseUrl, { id, startAt, endAt, isPublic });

export const deleteAppointment = (id) => api().delete(`${baseUrl}/${id}`);

export const getAppointmentsByPatient = () => api().get(baseUrl + "/patient");

export const getPastAppointmentsByPatient = () =>
  api().get(baseUrl + "/patient/past");

export const getSlotsDayByTherapist = (therapistId) =>
  api().get(`${baseUrl}/therapist/${therapistId}/slots/day`);

export const getSlotsTimeByAppointment = (appointmentId) =>
  api().get(`${baseUrl}/${appointmentId}/slots/time/`);

export const savePatientToAppointment = (appointmentId) =>
  api().patch(`${baseUrl}/${appointmentId}/patient`);

export const getAppointmentsToApprove = () =>
  api().get(`${baseUrl}/to-approve/therapist`);

export const approveAppointment = (appointmentId) =>
  api().patch(`${baseUrl}/${appointmentId}/approve`);

export const cancelAppointment = (appointmentId, { reason, source }) =>
  api().patch(`${baseUrl}/${appointmentId}/cancel`, { reason, source });

export const getAppointmentByIdToConfirmation = (id) =>
  api().get(`${baseUrl}/${id}/confirmation`);

export const createAppointMentWithPatient = ({ id, startAt, endAt, patient }) =>
  api().post(`${baseUrl}/with-patient`, { id, startAt, endAt, patient });
