import api from "./api-service";
import querystring from "querystring";

export const findAll = (query) => {
  return api().get(`/users?${querystring.stringify(query)}`);
};

export const findUserToTherapy = (filter) => {
  return api().get(`/users/to-therapy?${querystring.stringify(filter)}`);
};

export const create = (user) => {
  return api().post("/users", user);
};

export const createOrUpdateByPatient = (user) => {
  return api().post("/users/patient", user);
};

export const update = (user) => {
  return api().put("/users/app", user);
};

export const acceptTerms = () => {
  return api().patch("/users/terms-and-privacy");
};

export const login = (user) => {
  user.app = "APP";
  return api().post("/auth/login", user);
};

export const getByToken = () => {
  return api().get("/users/token");
};

export const updatePassword = ({
  oldPassword,
  newPassword,
  confirmNewPassword,
}) => {
  return api().patch("/users/password", {
    oldPassword,
    newPassword,
    confirmNewPassword,
  });
};

export const forgotPassword = (mail) =>
  api().post("/users/forgot-password", { mail });

export const decodeTokenForgotPassword = (token) =>
  api().post("/users/forgot-password/decode", { token });

export const resetPassword = (dto) => api().post("/users/reset-password", dto);

export const confirmRegistry = (token) =>
  api().post("/users/confirm-registry", { token });

export const verifyPatientAbleToAppoint = () =>
  api().get("/users/patient/able-to-appoint");

export const updateUserDataToAppoint = ({ phone, document }) =>
  api().patch("/users/user-data/to-appoint", { phone, document });
