const state = {
  selectedPlan: {},
};

const mutations = {
  setSelectedPlan(state, plan) {
    state.selectedPlan = plan;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
