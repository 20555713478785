import { findAll } from "@/services/local/vascular-phenomena-service.js";

const state = {
  vascularPhenomena: [],
  selected: {},
};

const mutations = {
  setVascularPhenomena(state, value) {
    state.vascularPhenomena = value;
  },
  setSelected(state, value) {
    state.selected = value;
  },
};

const actions = {
  async load({ commit }) {
    const response = await findAll();
    commit("setVascularPhenomena", response);
  },
  selectOne({ commit, dispatch }, value) {
    commit("setSelected", value);
    dispatch(
      "resolutionZones/showDialog",
      {
        returnAction: "vascularPhenomena/handleReturnResolutionZone",
        validateAction: "vascularPhenomena/verifyIfAlreadyAdded",
      },
      { root: true }
    );
  },
  handleReturnResolutionZone(
    { state, commit },
    {
      therapistComments,
      patientComments,
      resolutionZone,
      affectedSpotParTrauma,
      trackingPointSide,
      impactionPointSide,
    }
  ) {
    const diagnose = {
      vascularPhenomenon: state.selected,
      resolutionZone,
      therapistComments,
      patientComments,
      diagnoseResolutionZone: {
        resolutionZone,
        affectedSpotParTrauma,
        trackingPointSide,
        impactionPointSide,
      },
    };

    commit("diagnosis/addVascularPhenomena", diagnose, { root: true });
  },
  verifyIfAlreadyAdded({ state, rootState }, { resolutionZone }) {
    return new Promise((resolve, reject) => {
      const exists = rootState.diagnosis.diagnose.vascularPhenomena.some(
        (diagnose) =>
          diagnose.vascularPhenomenon.id === state.selected.id &&
          diagnose.resolutionZone.id === resolutionZone.id
      );
      if (exists) {
        reject({
          message:
            "Combinação de Fenômeno Vascular e Zona de Resolução já adicionado ao diagnóstico.",
        });
      }
      resolve();
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
